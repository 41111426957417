import { useEffect, useState } from "react";
import { useGet } from "../../../hooks/useGet";
import { ProductRewardsResponse } from "./product-reward.interface";

function useGetRewards() {
  const {
    getData,
    data: rewards,
    isLoading,
  } = useGet<ProductRewardsResponse[]>();

  useEffect(() => {
    async function fetch() {
      await getData("products/rewards");
    }
    fetch();
  }, []);

  return { rewards, isLoading };
}

export function useGetActiveRewards() {
  const { rewards, isLoading } = useGetRewards();

  const [activeRewards, setRewards] = useState<ProductRewardsResponse[]>([]);

  useEffect(() => {
    if (!rewards) return;
    setRewards(rewards.filter((reward) => reward.active));
  }, [rewards]);

  return { activeRewards, isLoading, setRewards };
}
