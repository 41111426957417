import styles from './InfoBox.module.css'

export default function InfoBox({ title, ammount, amountFontSize = "40px", elementId = "" }: any) {
  return (
    <div className={styles.box} id={elementId}>
      <h3>{title}</h3>
      <h2 style={{ "fontSize": amountFontSize }}>{ammount}</h2>
    </div>
  )
}
