import { useNavigate } from "react-router-dom";
import Button from "../../components/Button/Button";
import Spacer from "../../components/Spacer/Spacer";
import styles from "../../components/Table/Table.module.css";
import TitleContainer from "../../components/TitleContainer/TitleContainer";
import { ReactComponent as Edit } from '../../images/edit.svg';
import { ClientResponse } from "./clients.types";
import { useGetClients } from "./useGetClients";
import Loading from "../../components/Loading/Loading";


export default function Clients() {
  const { clients, isLoading, error } = useGetClients();
  const navigate = useNavigate();

  const handleEdit = (client: ClientResponse) => {
    navigate("/clients/edit", { state: { client } });
  };

  if (isLoading || !clients) {
    return <Loading show={true} />
  }
  if (error) {
    return <p>Error: {error}</p>;
  }
  return (
    <TitleContainer title="Clientes">
      <Button
        disabled={false}
        text={"Nuevo"}
        onClick={() => {
          navigate("/clients/create");
        }}
        styleOverride={{ marginLeft: "auto" }}
      />
      <Spacer space="40px"></Spacer>
      <table className={styles.container}>
        <thead>
          <tr>
            <th></th>
            <th>Nombre</th>
            <th>Descripción</th>
            <th style={{ textAlign: "center" }}>Editar</th>
          </tr>
        </thead>
        <tbody>
          <>
            {clients!.map((data, i) => (
              <tr key={i} style={{ backgroundColor: !data.active ? '#f64d4d' : undefined }}>
                <td style={{ textAlign: "center" }}>
                  <img src={data.logo || ""} alt="" height={40} />
                </td>
                <td>{data.title}</td>
                <td>{data.subtitle}</td>
                <td style={{ cursor: "pointer", textAlign: "center" }}>
                  <Edit onClick={() => handleEdit(data)} />
                </td>
              </tr>
            ))}
          </>
        </tbody>
      </table>
    </TitleContainer>
  );
}
