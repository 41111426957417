import { useContext, useEffect, useState } from "react";
import { FormProvider, SubmitHandler, useForm } from "react-hook-form";
import { useLocation, useParams } from "react-router-dom";
import { SyncLoader } from "react-spinners";
import Switch from "react-switch";
import { Context } from "../../App";
import { useLocalStorage } from "../../hooks/useLocalStorage";
import { CampaignDetailedResponse } from "../../pages/Campaign/campaign.types";
import { LoginClientKey } from "../../Types/login.types";
import { formatCurrency } from "../../utils/format.utils";
import {
  isFutureDateValidation,
  nonSpecialCharactersValidation,
} from "../../utils/formValidations.utils";
import Button from "../Button/Button";
import CurrencyInput from "../CurrencyInput/CurrencyInput";
import TextCombo from "../TextCombo/TextCombo";
import TextField from "../TextField/TextField";
import Tooltip from "../Tooltip/Tooltip";
import styles from "./CampaignFormContainer.module.css";

type FormData = {
  title: string;
  key: string;
  client: string;
  paymentGateway: string;
  coin: string;
  awards: number;
  objective: string;
  commission: number | string | null;
  active: boolean;
  endDate: Date | string;
  clientKey: string;
  landingUrl: string;
  facebook: string;
  instagram: string;
  tiktok: string;
};

type Props = {
  createNewCampaign: (data: any) => Promise<void>;
  campaign?: CampaignDetailedResponse;
  loading: boolean;
  hasTyC: boolean;
  hasCredentials: boolean;
};

function CampaignFormContainer({
  createNewCampaign,
  campaign,
  loading,
  hasTyC,
  hasCredentials,
}: Props) {
  const [clientsKeys, setClientsKeys] = useState<any[]>([]);
  const [active, setActive] = useState<boolean>(campaign?.active || false);
  let context = useContext(Context);
  const { key } = useParams();
  const { getItem: getClientKeys } = useLocalStorage<LoginClientKey[]>();
  const { state } = useLocation();

  const formMethods = useForm<FormData>({
    defaultValues: {
      key: "new",
      title: "",
      client: "",
      paymentGateway: "mercadopagoar",
      coin: "ARS",
      awards: 0,
      objective: "",
      commission: 0,
      endDate: "",
      active: false,
      clientKey: "",
      landingUrl: "",
      facebook: "",
      instagram: "",
      tiktok: "",
    },
  });

  const { handleSubmit, control, watch, reset, setValue } = formMethods;

  useEffect(() => {
    setValue("coin", watch("paymentGateway") === "paypal" ? "USD" : "ARS");
  }, [watch("paymentGateway")]);

  useEffect(() => {
    if (clientsKeys) {
      setClientsKeys(getClientKeys("clientsKeys") || []);
    }

    if (state && state.client) {
      setValue("client", state.client.title);
      setValue("clientKey", state.client.key);
    }
    if (!campaign) return;

    reset({
      key: campaign?.key || "new",
      client: campaign.client.title,
      title: campaign?.title || "",
      paymentGateway: "mercadopagoar",
      coin: campaign?.coin || "ARS",
      awards: campaign?.awards || 0,
      objective: formatCurrency(campaign?.objective, "es") || "",
      endDate: campaign?.endDate || "",
      clientKey: campaign?.client.key || "",
      landingUrl: campaign?.landingUrl || "",
      facebook: campaign?.facebook || "",
      instagram: campaign?.instagram || "",
      tiktok: campaign?.tiktok || "",
      commission:
        Math.round(parseFloat(campaign?.commission?.replace(",", ".")) * 100) ||
        0,
    });
    return;
  }, [campaign]);

  const onSubmit: SubmitHandler<FormData> = (data: FormData, e: any) => {
    e.preventDefault();
    const {
      key,
      title,
      paymentGateway,
      coin,
      awards,
      objective,
      commission,
      endDate,
      clientKey,
      landingUrl,
      facebook,
      instagram,
      tiktok,
    } = data;

    const plainObjective = objective.replace(/[^0-9]/g, "");

    let commissionValue = commission;
    if (typeof commissionValue === "string") {
      commissionValue = parseFloat(commissionValue.replace(",", "."));
    }

    createNewCampaign({
      key,
      title,
      paymentGateway,
      coin,
      awards,
      objective: plainObjective,
      endDate: endDate || null,
      clientKey,
      active,
      commission: commissionValue,
      landingUrl: landingUrl || null,
      facebook: facebook || null,
      instagram: instagram || null,
      tiktok: tiktok || null,
    });
  };

  const showError = (message: string) => {
    context?.showErrorModal("Error", message);
  };

  const handleChangeSwitch = (checked: boolean) => {
    if (checked) {
      if (campaign && !hasCredentials) {
        showError(
          "Para activar la campaña, por favor, coloque las credenciales de pago."
        );
        return;
      }

      if (campaign?.client.isClub) {
        if (!campaign) {
          showError(
            "Para activar la campaña, por favor, suba los términos y condiciones, el logo y el color de fondo."
          );
          return;
        }

        const { tycPath, commission, theme } = campaign;

        const { email } = theme?.data || {};
        const { backgroundColor, fontColor } = email || {};

        if (!tycPath && !hasTyC) {
          showError(
            "Para activar la campaña, por favor, suba los términos y condiciones."
          );
          return;
        }

        if (!commission) {
          showError(
            "Para activar la campaña, por favor, coloque una comisión."
          );
          return;
        }

        if (!backgroundColor) {
          showError(
            "Para activar la campaña, por favor, coloque un color de fondo en la personalización de email."
          );
          return;
        }

        if (!fontColor) {
          showError(
            "Para activar la campaña, por favor, coloque un color de fuente en la personalización de email."
          );
          return;
        }
      }
    }

    setActive(checked);
  };

  useEffect(() => {
    setActive(campaign?.active || false);
  }, [campaign?.active]);

  return (
    <>
      <FormProvider {...formMethods}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className={styles.container}>
            <TextField
              label="Cliente"
              props={{
                control,
                name: "client",
                rules: {
                  required: true,
                },
              }}
              readOnly
            />
            <TextField
              label="Título"
              props={{
                name: "title",
                control,
                rules: {
                  required: true,
                  pattern: nonSpecialCharactersValidation,
                },
              }}
            />
            <TextField
              label="Fecha de finalización"
              props={{
                name: "endDate",
                control,
                rules: {
                  validate: { isFutureDate: isFutureDateValidation },
                  required: false,
                },
              }}
              type="date"
            />
            <CurrencyInput
              label="Objetivo"
              props={{
                name: "objective",
                control,
                rules: {
                  required: true,
                },
              }}
            />
            <TextCombo
              label="Método de pago"
              props={{
                control,
                name: "paymentGateway",
                rules: { required: true },
              }}
            >
              <option value={"mercadopagoar"}>Mercado Pago</option>
            </TextCombo>
            <TextField
              label="Cantidad de ganadores del sorteo"
              props={{
                name: "awards",
                control,
                rules: {
                  required: true,
                  min: {
                    value: 0,
                    message: "Ingrese un valor mayor o igual a cero",
                  },
                },
              }}
              type="number"
            />
            <TextField
              label="Url landing"
              props={{
                name: "landingUrl",
                control,
                rules: {
                  required: false,
                },
              }}
            />
            {key !== "new" && (
              <>
                <TextField
                  label="Facebook"
                  props={{
                    control,
                    name: "facebook",
                    rules: {
                      required: false,
                    },
                  }}
                />
                <TextField
                  label="Instagram"
                  props={{
                    control,
                    name: "instagram",
                    rules: {
                      required: false,
                    },
                  }}
                />
                <TextField
                  label="TikTok"
                  props={{
                    control,
                    name: "tiktok",
                    rules: {
                      required: false,
                    },
                  }}
                />
              </>
            )}

            <div className={styles.switchContainer}>
              <label>
                <span>Activo</span>
              </label>
              <Switch
                onChange={handleChangeSwitch}
                checked={active}
                className={styles.reactSwitch}
                disabled={
                  context?.getUserData()?.role === "adminCKO" && key !== "new"
                    ? false
                    : true
                }
              />
              {context?.getUserData()?.role !== "adminCKO" && (
                <Tooltip tooltipMessage="Para cambiar el estado de la campaña, por favor ponte en contacto con el administrador." />
              )}
            </div>
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              marginTop: "1.5rem",
            }}
          >
            {!loading && (
              <Button
                text={campaign?.key ? "Actualizar campaña" : "Crear campaña"}
                type="submit"
                styleOverride={{ width: "300px" }}
              />
            )}
            <SyncLoader color="#f2c233" loading={loading} size={15} />
          </div>
        </form>
      </FormProvider>
    </>
  );
}

export default CampaignFormContainer;
