import { useEffect } from "react";
import { useGet } from "../../hooks/useGet";


export type ProductRewardResponse = {
  id: number,
  title: string;
  active: boolean;
  requiresShipping: boolean;
  uniqueCode: string;
  coupons: string;
};

export function useGetProductRewards() {
  const { getData, data, isLoading, error } = useGet<ProductRewardResponse[]>();

  useEffect(() => {
    async function fetch() {
      await getData("products/rewards");
    }
    fetch();
  }, []);

  const rewards = data;
  return { rewards, isLoading, error };
}
