import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import MailStyleContainer from "../../components/BodyMailContainer/MailStyleContainer";
import CampaignFormContainer from "../../components/CampaignFormContainer/CampaignFormContainer";
import FieldsetLayout from "../../components/FieldsetLayout/FieldsetLayout";
import { ModalMessage } from "../../components/ModalMessage/ModalMessage";
import PaymentCredentials from "../../components/PaymentCredentialsForm/PaymentCredentials";
import ProductsContainer from "../../components/ProductsContainer/ProductsContainer";
import RewardFormContainer from "../../components/RewardFormContainer/RewardFormContainer";
import RewardsContainer from "../../components/RewardsContainer/RewardsContainer";
import TermsAndConditions from "../../components/TermsAndConditions/TermsAndConditions";
import TitleContainer from "../../components/TitleContainer/TitleContainer";
import { useGet } from "../../hooks/useGet";
import {
  createCampaign,
  updateCampaign,
  updateEmailStyle,
} from "../../services/CampaignServices";
import styles from "./Campaign.module.css";
import { CampaignDetailedResponse } from "./campaign.types";

export default function EditCampaign() {
  const [campaign, setCampaign] = useState<CampaignDetailedResponse>();
  const { getData, data } = useGet<CampaignDetailedResponse>();
  const { key } = useParams();
  let navigate = useNavigate();
  const [showModal, setShowModal] = useState(false);
  const [showError, setShowError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("Error");
  const [loading, setLoading] = useState(false);
  const [loadingEmailStyle, setLoadingEmailStyle] = useState(false);
  const [hasTyC, setHasTyC] = useState(false);
  const [hasCredentials, setHasCredentials] = useState(false);
  const [rewardActive, setRewardActive] = useState(true);
  const [isClub, setIsClub] = useState(true);

  useEffect(() => {
    if (!campaign && key) getCampaign(key);
  }, [key]);

  useEffect(() => {
    setCampaign(data);
    setIsClub(!!data?.client.isClub);
    if (data?.rewards[0]) setRewardActive(data?.rewards[0].active);
  }, [data]);

  useEffect(() => {
    setHasTyC(false);
    setHasCredentials(false);
  }, []);

  const getCampaign = async (key: string) => {
    if (key === "new") {
      setCampaign(undefined);
      return;
    }
    await getData(`campaigns/${key}/detail`);
  };

  const createNewCampaign = async (data: any) => {
    try {
      setLoading(true);
      setShowError(false);
      const {
        title,
        key,
        coin,
        awards,
        objective,
        clientKey,
        active,
        commission,
        endDate,
        paymentGateway,
        landingUrl,
        facebook,
        instagram,
        tiktok,
      } = data;

      if (key === "new") {
        await createCampaign(
          title,
          coin,
          parseInt(awards),
          parseInt(objective),
          clientKey,
          commission,
          active,
          endDate,
          paymentGateway,
          landingUrl
        );
        setShowModal(true);
      } else {
        await updateCampaign(
          title,
          key,
          awards,
          objective,
          active,
          endDate,
          paymentGateway,
          landingUrl,
          commission,
          facebook,
          instagram,
          tiktok
        );
        setShowError(false);
        setShowModal(true);
      }
    } catch (error: any) {
      setShowModal(true);
      setShowError(true);
      console.log( error.response?.data?.message[0]);
      
      if (
        error.response?.data?.message ===
        "You have to set the payment credentials before campaign activation"
      ) {
        setErrorMessage(
          "Por favor configure las credenciales de la campaña para su activación."
        );
      } else {
        setErrorMessage(
          "Ha ocurrido un error, por favor intentelo nuevamente."
        );
      }
    } finally {
      setLoading(false);
    }
  };

  const uploadEmailStyle = async (data: any) => {
    if (!campaign) return;
    try {
      setLoadingEmailStyle(true);
      setShowError(false);

      await updateEmailStyle(
        campaign.key,
        data.headerFile,
        data.footerFile,
        data.backgroundColor,
        data.fontColor
      );
      setShowModal(true);
    } catch (error: any) {
      setShowModal(true);
      setShowError(true);
      if (
        error.response?.data?.message[0] ===
          "backgroundColor should not be empty" ||
        error.response?.data?.message[0] === "fontColor should not be empty"
      ) {
        setErrorMessage(
          "Por favor, seleccione correctamente un color para el fondo y la fuente."
        );
      } else if (
        error.response?.data?.message[0] === "headerFile should not be empty" ||
        error.response?.data?.message[0] === "footerFile should not be empty"
      ) {
        setErrorMessage(
          "Por favor, suba correctamente una imagen para el encabezado y el pie de página."
        );
      } else {
        setErrorMessage(
          "Ha ocurrido un error, por favor intentelo nuevamente."
        );
      }
      console.log(error, "error");
    } finally {
      setLoadingEmailStyle(false);
    }
  };

  const onAcceptCampaign = () => {
    setShowModal(false);
    if (key === "new") {
      navigate("/campaigns");
    }
  };

  const handleRewardActive = (value: boolean) => {
    setRewardActive(value);
  };

  return (
    <>
      <ModalMessage
        show={showModal}
        title={
          showError
            ? "Error"
            : `Campaña ${key === "new" ? "creada" : "editada"}`
        }
        message={
          showError
            ? errorMessage
            : `La campaña se ha ${
                key === "new" ? "creado" : "editado"
              } correctamente.`
        }
        type={showError ? "error" : "accept"}
        onAccept={() => onAcceptCampaign()}
      />
      <TitleContainer title={"Campaña"}  backURL={"/campaigns"}>
        <FieldsetLayout label="Datos de Campaña">
          <CampaignFormContainer
            createNewCampaign={createNewCampaign}
            campaign={campaign}
            loading={loading}
            hasTyC={hasTyC}
            hasCredentials={hasCredentials}
          />
        </FieldsetLayout>

        {campaign?.key && (
          <>
            <PaymentCredentials
              campaign={campaign}
              setHasCredentials={setHasCredentials}
            />

            {isClub && (
              <div className={styles.gridContainer}>
                <FieldsetLayout label="Personalización de Emails">
                  <MailStyleContainer
                    uploadEmailStyle={uploadEmailStyle}
                    campaign={campaign}
                    loading={loadingEmailStyle}
                  />
                </FieldsetLayout>
                <TermsAndConditions
                  campaignKey={campaign?.key}
                  url={campaign?.tycPath}
                  setHasTyC={setHasTyC}
                />
              </div>
            )}
            <ProductsContainer campaign={campaign} />

            {campaign.rewards.length > 0 && (
              <FieldsetLayout label="Certificado de donación">
                <>
                  {campaign.client.isClub && (
                    <RewardFormContainer
                      campaignKey={campaign.key}
                      reward={campaign.rewards[0]}
                      handleRewardActive={handleRewardActive}
                    />
                  )}

                  <RewardsContainer
                    campaign={campaign}
                    campaignKey={campaign.key}
                  />
                </>
              </FieldsetLayout>
            )}
          </>
        )}
      </TitleContainer>
    </>
  );
}
