import styles from "../../components/Table/Table.module.css";
import Spacer from "../../components/Spacer/Spacer";
import TitleContainer from "../../components/TitleContainer/TitleContainer";
import Button from "../../components/Button/Button";
import { useNavigate } from "react-router-dom";
import { useGetUsers } from "./useGetUsers";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit } from "@fortawesome/free-solid-svg-icons";
import { UsersResponse } from "./users.types";
import Loading from "../../components/Loading/Loading";

export default function Users() {
  const { users, isLoading, error } = useGetUsers();
  const navigate = useNavigate();

  const handleEdit = (user: UsersResponse) => {
    navigate("/users/edit", { state: { user } });
  };

  if (isLoading || !users) {
    return <Loading show={true} />
  }
  if (error) {
    return <p>Error: {error}</p>;
  }
  return (
    <TitleContainer title="Usuarios">
      <Button
        disabled={false}
        text={"Nuevo"}
        styleOverride={{ marginLeft: "auto" }}
        onClick={() => {
          navigate("/users/create");
        }}
      />
      <Spacer space="40px"></Spacer>
      <table className={styles.container}>
        <thead>
          <tr>
            <th>Email</th>
            <th>Nombre</th>
            <th>Apellido</th>
            <th>Cliente</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {users!.map((data, i) => (
            <tr key={i} style={{ backgroundColor: !data.active ? '#f64d4d' : undefined }}>
              <td>{data.email}</td>
              <td>{data.name}</td>
              <td>{data.lastName}</td>
              <td>{data.userClients}</td>
              <td>
                <FontAwesomeIcon icon={faEdit} onClick={() => handleEdit(data)} />
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </TitleContainer>
  );
}
