import { UseControllerProps, useController } from "react-hook-form";
import styles from "./TextField.module.css";
import WarningText from "../WarningText/WarningText";
import Tooltip from "../Tooltip/Tooltip";
import { HTMLAttributes } from "react";

type Props = {
  label?: string;
  type?: string;
  color?: string;
  readOnly?: boolean;
  placeholder?: string;
  props: UseControllerProps<any>;
  children?: any;
  tooltipMessage?: string;
  inputProps?: HTMLAttributes<HTMLInputElement>;
  row?: boolean;
};

function TextField({
  label,
  type = "text",
  tooltipMessage,
  color,
  readOnly = false,
  placeholder,
  props,
  inputProps,
  row = false,
}: Props) {
  const { field, fieldState } = useController(props);

  return (
    <div
      className={`${styles.container} ${row ? styles.row : ""}`}
    >
      <div className={styles.label}>
        <span
          style={{ color: color, display: "flex", gap: "3px" }}
          className={styles.span}
        >
          {label}
          {(props.rules?.required && label) && (
            <span style={{ fontSize: "1.1rem" }}>{"*"}</span>
          )}
        </span>
        {tooltipMessage && <Tooltip tooltipMessage={tooltipMessage} />}
      </div>
      <input
        type={type}
        placeholder={placeholder}
        className={styles.input}
        autoComplete="off"
        readOnly={readOnly}
        disabled={readOnly}
        {...field}
        {...inputProps}
      />
      {fieldState.error && (
        <>
          <WarningText
            text={fieldState.error?.message}
            show={fieldState.error?.message}
          ></WarningText>
          <WarningText
            text={"Ingrese una fecha válida y mayor a la actual."}
            show={fieldState.error?.type === "isFutureDate"}
          ></WarningText>
          <WarningText
            text={"El campo no puede estar vacío."}
            show={fieldState.error?.type === "isEmpty"}
          ></WarningText>
        </>
      )}
    </div>
  );
}

export default TextField;
