export type TUseLocalStorage<T> = {
    setItem: (key: string, value: T) => void,
    getItem: (key: string) => T | undefined,
    removeItem: (key: string) => void
};

export function useLocalStorage<T>(): TUseLocalStorage<T> {
    const setItem = (key: string, value: T): void => {
        try {
            window.localStorage.setItem(key, JSON.stringify(value));
        } catch (error) {
            window.console.log(error);
        }
    };

    const getItem = (key: string): T | undefined => {
        try {
            const item = window.localStorage.getItem(key);

            if (item === null) return undefined;

            return JSON.parse(item);
        } catch (error) {
            window.console.log(error);

            return undefined;
        }
    };

    const removeItem = (key: string): void => {
        try {
            window.localStorage.removeItem(key);
        } catch (error) {
            window.console.log(error);
        }
    };

    return {
        setItem,
        getItem,
        removeItem
    };
}