import { axiosAuth } from "./HttpService";

type ClientResponse = {
  id: number;
  active: boolean;
  key: string;
  title: string;
  subtitle: string;
  logo: string | null;
  landingUrl: string | null;
  isClub: boolean;
};

export const updateClient = async (
  key: string,
  title: string,
  subtitle: string,
  status: boolean,
  file?: File
): Promise<any> => {
  const formData = new FormData();
  formData.append("title", title);
  formData.append("subtitle", subtitle);
  formData.append("status", status.toString());
  if (file) formData.append("file", file);

  const resp = await axiosAuth
    .put(`/clients/${key}/`, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    })
    .then((response: any) => response.data);

  return resp;
};

export const getTemplateDataBase = async (): Promise<any> => {
  return axiosAuth
    .get("users/import/excel-template", { responseType: "arraybuffer" })
    .then((response) => {
      const blob = new Blob([response.data], {
        type: "application/vnd.ms-excel",
      });
      const link = document.createElement("a");
      link.href = window.URL.createObjectURL(blob);
      link.setAttribute("download", "users-database.xlsx");
      document.body.appendChild(link);
      link.click();
    });
};

export const uploadMembersDataBase = async (
  key: string | undefined,
  formData: FormData
): Promise<any> => {
  const resp = await axiosAuth
    .post(`/clients/${key}/import/members-database`, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    })
    .then((response: any) => response.data);

  return resp;
};

export const getAllClientsForUser = async () => {
  try {
    const response = await axiosAuth.get("/clients");
    const clients: ClientResponse[] = response.data;
    const mappedClients = clients.map((client) => ({
      key: client.key,
      description: client.title,
      isClub: client.isClub,
    }));
    localStorage.setItem("clientsKeys", JSON.stringify(mappedClients));
  } catch (e) {}
};
