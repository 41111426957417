import Button from "../../components/Button/Button";
import LoginBox from "../../components/LoginBox/LoginBox";
import Spacer from "../../components/Spacer/Spacer";
import TextInput from "../../components/TextInput/TextInput";
import styles from "./Login.module.css";
import { useNavigate, useParams } from "react-router-dom";
import { useContext, useState } from "react";
import { Context } from "../../App";
import { ChangePasswordService } from "../../services/LoginServices";

export default function ChangePassword() {
  const [fields, setFields] = useState({ password: "", repeatPassword: "" });
  let context = useContext(Context);
  const params = useParams();
  const hash = params.hash;

  let navigate = useNavigate();

  const onClickChangePassword = async () => {
    ChangePasswordService(fields.password, hash).then(() => {
      context?.showAcceptModal(
        "PASSWORD CHANGED",
        "The password has changed.",
        () => {
          navigate("/");
        }
      );
    }).catch(() => {
      context?.showErrorModal('ERROR ON PASSWORD CHANGE', 'The hash is invalid, try again later');
    });
  };

  const handleSetState = (name: string, value: string) => {
    setFields({ ...fields, [name]: value });
  };

  const handleValidate = (name: string) => {
    switch (name) {
      case "password":
        if (fields[name] === "") return "Password has to be completed";
        break;
      case "repeatPassword":
        if (fields[name] === "") return "Password repeated has to be completed";
        if (fields[name] !== fields["password"]) return "Passwords are not the same";
        break;
    }

    return "";
  };

  return (
    <div className={styles.container}>
      <LoginBox title="CHANGE PASSWORD">
        <div className={styles.form}>
          <TextInput
            label="New password"
            initialValue={fields.password}
            name="password"
            placeholder=""
            setValue={handleSetState}
            type="password"
            validate={handleValidate}
          ></TextInput>
          <TextInput
            label="Repeat password"
            initialValue={fields.repeatPassword}
            name="repeatPassword"
            placeholder=""
            setValue={handleSetState}
            type="password"
            validate={handleValidate}
          ></TextInput>
          <Spacer space="80px" />
          <Button disabled={(handleValidate("password") !== "" || handleValidate("repeatPassword") !== "")} text="GUARDAR" onClick={onClickChangePassword}></Button>
        </div>
      </LoginBox>
    </div>
  );
}
