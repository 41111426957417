import { ChangeEvent, useEffect } from "react";
import styles from "./TermsAndConditions.module.css";
import { useState } from "react";
import Button from "../Button/Button";
import { updateTermsAndConditions } from "../../services/CampaignServices";
import { ModalMessage } from "../ModalMessage/ModalMessage";
import PdfViewer from "../PdfViewer/PdfViewer";
import FileInput from "../FileInput/FileInput";
import { SyncLoader } from "react-spinners";

export default function TermsAndConditions({ campaignKey, url, setHasTyC }: any) {

  const [file, setFile] = useState<File | undefined>();
  const [fileUrl, setFileUrl] = useState<string | undefined>();
  const [showModal, setShowModal] = useState(false);
  const [showError, setShowError] = useState(false);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (url){
      setFileUrl(url)
      setHasTyC(true)
    }
  }, [url])



  const handleFileChange = (e: ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files.length > 0) {
      setFile(e.target.files[0]);
      setFileUrl(URL.createObjectURL(e.target.files[0]) || "");
    } else {
      setFileUrl(undefined)
    }
  };

  const uploadDocument = async () => {
    const formData = new FormData();
    if (file) formData.append("file", file);
    
    try {
      setLoading(true);
      const response = await updateTermsAndConditions(campaignKey, formData);

      if (response) {
        setShowError(false);
        setShowModal(true);
        setHasTyC(true);
      }
    } catch (error) {
      setShowModal(true);
      setShowError(true);
      console.error(error);
    } finally {
      setLoading(false);
    }
  };



  return (
    <>
      {showModal && (
        <ModalMessage
          show={showModal}
          title={showError ? "Error" : "Éxito"}
          message={
            showError
              ? "Ha ocurrido un error al subir el documento"
              : "Cambios guardados correctamente"
          }
          type={showError ? "error" : "accept"}
          onAccept={() => setShowModal(false)}
        />
      )}
      <fieldset className={styles.fieldset}>
        <legend>Términos y Condiciones</legend>
        <div
          style={{
            display: "flex",
            justifyContent: "space-evenly",
            gap: "2rem",
          }}
        >
          <FileInput
            handleFileChange={handleFileChange}
            inputName="termsFile"
            label="Subir archivo .pdf"
            accept=".pdf"
          />
          {!loading &&
            <Button
              text={"Guardar cambios"}
              onClick={uploadDocument}
              styleOverride={{ width: "200px" }}
            />}
          <SyncLoader color="#f2c233" loading={loading} size={15} />
        </div>
        <div className={styles.pdfContainer}>
          {fileUrl && (
            <PdfViewer fileUrl={fileUrl} />
          )}
        </div>
      </fieldset>
    </>
  );
}
