import {
  ChangeEvent,
  Dispatch,
  SetStateAction,
  useEffect,
  useState,
} from "react";
import { useLocalStorage } from "../../hooks/useLocalStorage";
import { LoginClientKey } from "../../Types/login.types";
import styles from "../CampaignFilters/CampaignFilters.module.css";

type Props = {
  setClient: Dispatch<
    SetStateAction<{ key: string; title: string; isClub: boolean }>
  >;
};

export default function ClientSelector({ setClient }: Props) {
  const [clients, setClients] = useState<any[]>([]);
  const { getItem } = useLocalStorage<LoginClientKey[]>();

  useEffect(() => {
    setClients(getItem("clientsKeys") || []);
  }, []);

  function handleOnChangeClient(e: ChangeEvent<HTMLSelectElement>) {
    const key = e.currentTarget.value;
    const client = clients.find((c) => c.key === key);
    setClient({
      key: client?.key || "",
      title: client?.description || "",
      isClub: client?.isClub || "",
    });
  }

  return (
    <div
      className="container"
      style={{
        display: "flex",
        flexDirection: "row",
        justifyContent: "flex-start",
        alignItems: "center",
        gap: "10px",
      }}
    >
      <span className={styles.text}>Cliente</span>
      <select
        onChange={(e) => {
          handleOnChangeClient(e);
        }}
        className={styles.genericInput}
      >
        <option value="" selected>
          Todos los clientes
        </option>
        {clients.map((client) => (
          <option value={client.key} key={client.key}>
            {client.description}
          </option>
        ))}
      </select>
    </div>
  );
}
