import styles from "./Button.module.css";

interface Props {
  text?: string;
  onClick?: () => void;
  styleOverride?:  React.CSSProperties;
  disabled?: boolean;
  type?: "button" | "submit" | "reset";
  children?: JSX.Element[] | JSX.Element;
}

export default function Button({
  text,
  onClick,
  styleOverride = {},
  disabled = false,
  type = "button",
  children,
}: Props) {
  const handleOnClick = () => {
    if (onClick) onClick();
  };
  return (
    <>
      {disabled ? (
        <button
          className={styles.button}
          disabled={true}
          style={styleOverride}
          type={type}
        >
          {text}
          {children}
        </button>
      ) : (
        <button
          className={styles.button}
          onClick={handleOnClick}
          style={styleOverride}
          type={type}
        >
          {text}
          {children}
        </button>
      )}
    </>
  );
}
