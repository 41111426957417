import Button from "../../components/Button/Button";
import LoginBox from "../../components/LoginBox/LoginBox";
import Spacer from "../../components/Spacer/Spacer";
import TextInput from "../../components/TextInput/TextInput";
import styles from "./Login.module.css";
import { useNavigate } from "react-router-dom";
import { useContext, useState } from "react";
import { Context } from "../../App";
import { RecoverPasswordService } from "../../services/LoginServices";

export default function RecoverPassword() {
  const [fields, setFields] = useState({ email: "" });
  let context = useContext(Context);

  let navigate = useNavigate();

  const onAccept = () => {
    navigate("/");
  };

  const onClickRecoverPassword = async () => {
    if (fields.email !== "") {
      RecoverPasswordService(fields.email)
        .then(() => {
          context?.showAcceptModal("PASSWORD RECOVER", "Email sent!", onAccept);
        })
        .catch((e) => {
          context?.showErrorModal(
            "ERROR",
            "Oops, there was an error, please try again!"
          );
        });
    } else context?.showErrorModal("INVALID FIELDS", "Email is required");
  };

  const handleSetState = (name: string, value: string) => {
    setFields({ ...fields, [name]: value });
  };

  const handleValidate = (name: string) => {
    switch (name) {
      case "email":
        if (fields[name] === "") return "Email has to be completed";
        break;
    }

    return "";
  };

  return (
    <div 
    className={styles.container}
    style={{height: 'calc(100vh - 115px)'}}
    >
      <LoginBox title="RECOVER PASSWORD">
        <div className={styles.form}>
          <TextInput
            label="Email"
            initialValue={fields.email}
            name="email"
            placeholder=""
            setValue={handleSetState}
            type="text"
            validate={handleValidate}
          ></TextInput>
          <Spacer space="150px" />
          {fields.email !== "" ? (
            <Button
              text="Enviar email"
              onClick={onClickRecoverPassword}
            ></Button>
          ) : (
            <Button
              text="Enviar email"
              onClick={() => { }}
              styleOverride={{ backgroundColor: "gray" }}
            ></Button>
          )}
        </div>
      </LoginBox>
    </div>
  );
}
