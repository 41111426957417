import { ChangeEvent, useContext, useState } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import { useLocation, useNavigate } from "react-router-dom";
import Switch from "react-switch";
import { Context } from "../../App";
import Button from "../../components/Button/Button";
import FieldsetLayout from "../../components/FieldsetLayout/FieldsetLayout";
import styles from "../../components/Table/Table.module.css";
import TextField from "../../components/TextField/TextField";
import TitleContainer from "../../components/TitleContainer/TitleContainer";
import {
  getAllClientsForUser,
  updateClient,
} from "../../services/ClientServices";
import { nonSpecialCharactersValidation } from "../../utils/formValidations.utils";
import stylesClient from "./Clients.module.css";
import { ClientResponse } from "./clients.types";
import Tooltip from "../../components/Tooltip/Tooltip";

type UserFormData = {
  title: string;
  subtitle: string;
  isClub: boolean;
};

interface LocationState {
  client: ClientResponse;
}

export default function EditClient() {
  const location = useLocation();
  const { client } = location.state as LocationState;
  const { title, subtitle, active, key, isClub } = client;

  const context = useContext(Context);

  const navigate = useNavigate();

  const [file, setFile] = useState<File>();
  const [imageSrc, setImageSrc] = useState(client.logo || "");

  const [status, setStatus] = useState<boolean>(active);
  const [toggleClub, setToggleClub] = useState<boolean>(isClub);

  const handleChange = (checked: boolean) => {
    setStatus(checked);
  };

  const handleIsClub = (checked: boolean) => {
    setToggleClub(checked);
  };

  const { handleSubmit, control } = useForm<UserFormData>({
    defaultValues: {
      title,
      subtitle,
      isClub,
    },
  });

  const onSubmit: SubmitHandler<UserFormData> = async (
    data: UserFormData,
    e: any
  ) => {
    e.preventDefault();

    const { title, subtitle } = data;

    await updateClient(key, title, subtitle, status, file)
      .then(() => {
        getAllClientsForUser();
        context?.showAcceptModal(
          "Éxito",
          "Cambios guardados correctamente",
          () => navigate(-1)
        );
      })
      .catch(() =>
        context?.showErrorModal(
          "Error",
          "Ha ocurrido un error al guardar los cambios"
        )
      );
  };

  const handleFileChange = (e: ChangeEvent<HTMLInputElement>) => {
    if (e.target.files) {
      setFile(e.target.files[0]);
      setImageSrc(URL.createObjectURL(e.target.files[0]));
    }
  };

  return (
    <TitleContainer title="Cliente" backURL="/clients">
      <FieldsetLayout label="Editar Cliente">
        <form
          onSubmit={handleSubmit(onSubmit)}
          className={stylesClient.gridContainer}
        >
          <TextField
            label="Nombre"
            props={{
              name: "title",
              control,
              rules: {
                required: true,
                pattern: nonSpecialCharactersValidation,
              },
            }}
          />
          <TextField
            label="Descripción"
            props={{
              name: "subtitle",
              control,
              rules: {
                required: true,
                pattern: nonSpecialCharactersValidation,
              },
            }}
          />
          <label style={{ display: "flex", alignItems: "center", gap: "10px" }}>
            <span
              style={{
                color: "#f2c233",
                fontSize: "1rem",
                fontFamily: "Roboto",
                fontWeight: 600,
              }}
            >
              ¿Es club?
            </span>
            <Switch
              onChange={handleIsClub}
              checked={toggleClub}
              className={styles.reactSwitch}
              disabled
            />
          </label>
          <label style={{ display: "flex", alignItems: "center", gap: "10px" }}>
            <span
              style={{
                color: "#f2c233",
                fontSize: "1rem",
                fontFamily: "Roboto",
                fontWeight: 600,
              }}
            >
              Activo
            </span>
            <Switch
              onChange={handleChange}
              checked={status}
              className={styles.reactSwitch}
            />
            <Tooltip tooltipMessage="El cliente podrá desactivarse únicamente si no tiene campañas activas." />
          </label>

          <div className={stylesClient.fileInput}>
            {imageSrc ? (
              <img id="product" src={imageSrc} alt="product" />
            ) : (
              <div></div>
            )}
            <input
              type="file"
              name="file"
              id="file"
              onChange={handleFileChange}
              accept="image/*"
            />
            <label htmlFor="file" style={{ cursor: "pointer" }}>
              Subir imagen
            </label>
          </div>
          <div className={stylesClient.btn}>
            <Button
              text="Editar"
              type="submit"
              styleOverride={{ margin: "0 auto" }}
            />
          </div>
        </form>
      </FieldsetLayout>
    </TitleContainer>
  );
}
