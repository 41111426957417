import { useContext, useEffect, useState } from "react";
import Spacer from "../../components/Spacer/Spacer";
import TitleContainer from "../../components/TitleContainer/TitleContainer";
import { Context } from "../../App";
import { useNavigate } from "react-router-dom";
import CampaignFilters from "../../components/CampaignFilters/CampaignFilters";
import {
  getLottery,
  getCampaigns,
  postLottery,
} from "../../services/DashboardServices";
import CampaignInfoBox from "../../components/CampaignInfoBox/CampaignInfoBox";
import Button from "../../components/Button/Button";
import Table from "../../components/Table/LotteryTable";
import {
  CampaignInterface,
  LotteryData,
  Winner,
} from "../../Interfaces/campaign";
import Loading from "../../components/Loading/Loading";
import {
  ModalMessage,
  ModalTypes,
} from "../../components/ModalMessage/ModalMessage";
import { handleUnahutorizedException } from "../../utils/servicesUtils";

export default function Lottery() {
  let context = useContext(Context);
  let navigate = useNavigate();
  const [campaigns, setCampaigns] = useState<any>([]);
  const [campaign, setCampaign] = useState<CampaignInterface>();
  const [lotteryData, setLotteryData] = useState<LotteryData>();
  const [loading, setLoading] = useState<boolean>(false);
  const [showModal, setShowModal] = useState<boolean>(false);
  const { setShowLoading } = context!;

  const modal = {
    show: showModal,
    title: "¿Seguro que desea realizar el sorteo?",
    message: "Una vez realizado se notificará a los ganadores",
    type: ModalTypes.CONFIRM,
    onAccept: () => handleLottery(),
    onCancel: () => setShowModal(false),
  };

  useEffect(() => {
    if (!context?.getToken()) navigate("/");

    setShowLoading(true);
    if (context?.getToken()) {
      getCampaigns()
        .then((data) => {
          if (data) {
            setCampaigns(data);
            setCampaign(data[0] as CampaignInterface);
          }
        })
        .catch((error) => handleUnahutorizedException(error, navigate))
        .finally(() => setShowLoading(false));
    }
  }, []);

  useEffect(() => {
    if (campaign && context) {
      getLottery(campaign)
        .then((lotteryData) => {
          if (lotteryData.response) {
            setLotteryData(lotteryData.lottery);
          }
        })
        .catch((error) => handleUnahutorizedException(error, navigate));
    }
  }, [campaign]);

  const handleLottery = async () => {
    if (campaign && context) {
      setLoading(true);
      postLottery(campaign)
        .then((data) => {
          let winners: Winner[] = data.winners || [];
          if (winners) {
            setLotteryData({
              ...lotteryData,
              winners,
              raffleMade: true,
              raffleEnabled: false,
            } as LotteryData);
          }
          setLoading(false);
        })
        .catch((error) => handleUnahutorizedException(error, navigate))
        .finally(() => setLoading(false));
    }
  };

  useEffect(() => {
    if (loading === false) setShowModal(false);
  }, [loading]);

  return (
    <>
      {!modal.show || (
        <ModalMessage
          show={modal.show}
          title={modal.title}
          message={modal.message}
          type={modal.type}
          onAccept={modal.onAccept}
          onCancel={modal.onCancel}
        ></ModalMessage>
      )}
      {!loading || <Loading show={true} />}
      <Spacer space="50px"></Spacer>
      <TitleContainer title="Lottery">
        <CampaignFilters
          defaultOption={true}
          setOption={(id: any) => setCampaign(campaigns[id])}
        >
          {campaigns.map((campaign: any, i: string) => (
            <option key={i} id={i}>
              {campaign.title}
            </option>
          ))}
        </CampaignFilters>
        <Spacer space="40px"></Spacer>
        {campaign && lotteryData?.awards ? (
          <>
            <CampaignInfoBox
              campaign={campaign.title}
              award={lotteryData?.awards.toString()}
            ></CampaignInfoBox>
            <Spacer space="40px"></Spacer>
            <Button
              disabled={!lotteryData.raffleEnabled}
              text={"Sortear"}
              onClick={() => setShowModal(true)}
            ></Button>
          </>
        ) : (
          <>
            <Spacer space="40px"></Spacer>
            <h2 style={{ width: "100%", height: "100%" }}>
              Esta campaña no otorga premios.
            </h2>
          </>
        )}
      </TitleContainer>
      <Spacer space="40px"></Spacer>
      {campaign &&
      lotteryData &&
      lotteryData.awards &&
      lotteryData.winners != null &&
      lotteryData?.winners?.length !== 0 ? (
        <TitleContainer title="Listado de ganadores" fontSize={"20px"}>
          <Table
            rows={lotteryData?.awards}
            winners={lotteryData.winners}
          ></Table>
          <Spacer space="40px"></Spacer>
        </TitleContainer>
      ) : (
        ""
      )}
    </>
  );
}
