import { useCallback, useContext, useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Context } from "../../App";
import Button from "../../components/Button/Button";
import CampaignFilters from "../../components/CampaignFilters/CampaignFilters";
import { BarGraph } from "../../components/Graphs/BarGraph";
import { PieGraph } from "../../components/Graphs/PieGraph";
import InfoBox from "../../components/InfoBox/InfoBox";
import Progress from "../../components/Progress/Progress";
import Spacer from "../../components/Spacer/Spacer";
import TitleContainer from "../../components/TitleContainer/TitleContainer";
import TopContributors from "../../components/TopContributors/TopContributors";
import {
  createDashboardReport,
  getCampaigns,
  getProgressData,
  getReportRaised,
} from "../../services/DashboardServices";
import {
  Country,
  getConversionRate,
  getCountriesReport,
  getDailyConvertions,
  getTopContributors,
} from "../../services/ReportServices";
import { formatCurrency } from "../../utils/format.utils";
import { handleUnahutorizedException } from "../../utils/servicesUtils";
import style from "./Dashboard.module.css";
import stylesButton from "../../components/Button/Button.module.css";
import { getColorList } from "../../utils/utils";
import { CampaignResponse } from "../Campaign/campaign.types";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faRefresh } from "@fortawesome/free-solid-svg-icons";
import { ConversionRateData, CountriesData, DashboardData } from "./dashboard.types";
import { getCampaignDetail } from "../../services/CampaignServices";

export default function Dashboard() {
  let context = useContext(Context);
  let navigate = useNavigate();

  const [dashboardData, setDashboardData] = useState<DashboardData>({
    campaigns: [],
    selectedCampaign: null,
    reportData: {
      show: false,
      totalCampaign: 0,
      contributors: 0,
      contributions: 0,
      totalCKO: 0,
      hasCommission: false,
    },
    campaignProgress: null,
    graphData: {
      conversionRate: null,
      contributorsCountries: null,
      sevenDaysConvertions: [],
    },
    topContributors: [],
  });

  const { setShowLoading, loading } = context!;
  const ref = useRef<HTMLDivElement>(null);

  const downloadReport = () => {
    if (!dashboardData.graphData.contributorsCountries || !dashboardData.graphData.conversionRate) return;

    const countriesData: Country[] = dashboardData.graphData.contributorsCountries.data.labels.map((label: string, index: number): Country => ({
      country: label,
      count: dashboardData.graphData.contributorsCountries!.data.datasets[0].data[index]
    }));

    createDashboardReport(
      ref,
      dashboardData.selectedCampaign,
      dashboardData.campaignProgress?.percentage,
      dashboardData.campaignProgress?.totalAmount,
      dashboardData.campaignProgress?.objective,
      countriesData,
      dashboardData.graphData.conversionRate
    );
  };

  useEffect(() => {
    if (!context?.getToken()) navigate("/");

    setShowLoading(true);
    if (context?.getToken()) {
      getCampaigns()
        .then((data) => {
          if (data) {
            setDashboardData(prev => ({
              ...prev,
              campaigns: data,
              selectedCampaign: data[0],
            }));
          }
        })
        .catch((error) => {
          handleUnahutorizedException(error, navigate);
        })
        .finally(() => {
          setShowLoading(false);
        });
    }
  }, []);

  useEffect(() => {
    if (dashboardData.selectedCampaign && context?.getToken()) {
      fetchCampaignData(dashboardData.selectedCampaign);
    }
  }, [dashboardData.selectedCampaign]);

  const fetchCampaignData = useCallback(async (campaign: CampaignResponse) => {
    try {
      setShowLoading(true);
      const [progress, reportRaised, countries, conversionRate, topContributors, dailyConvertions, campaignDetail] = await Promise.all([
        getProgressData(campaign),
        getReportRaised(campaign.key),
        getCountriesReport(campaign.key),
        getConversionRate(campaign.key),
        getTopContributors(campaign.key),
        getDailyConvertions(campaign.key),
        getCampaignDetail(campaign.key),
      ]);

      setDashboardData(prev => ({
        ...prev,
        campaignProgress: progress,
        reportData: {
          show: true,
          totalCampaign: reportRaised.dataset[0].data,
          contributors: reportRaised.dataset[1].data,
          contributions: reportRaised.dataset[2].data,
          totalCKO: reportRaised.dataset[3]?.data || 0,
          hasCommission: campaignDetail.commission === "0.00" ? false : true,
        },
        graphData: {
          conversionRate: processConversionRateData(conversionRate),
          contributorsCountries: processCountriesData(countries),
          sevenDaysConvertions: dailyConvertions,
        },
        topContributors,
      }));
    } catch (error: any) {
      handleUnahutorizedException(error, navigate);
    } finally {
      setShowLoading(false);
    }
  }, [dashboardData.selectedCampaign]);

  const processConversionRateData = (data: { approvedCarts: number, invalidCarts: number }): ConversionRateData => {
    return {
      labels: [
        "Contribuciones incompletas",
        "Contribuciones aprobadas",
      ],
      datasets: [
        {
          label: "# of Votes",
          data: [data.invalidCarts, data.approvedCarts],
          backgroundColor: [
            "#f2c233",
            "rgba(15, 79, 120, 1)",
          ],
          borderColor: ["#f2c233", "rgba(15, 79, 120, 1)"],
          borderWidth: 1,
        },
      ],
    };
  };

  const processCountriesData = (data: { countriesList: { country: string, count: number }[], totalUsers: number }): CountriesData => {
    const sortedCountries =
      data.countriesList
        .sort(({ count: a }: { count: number }, { count: b }: { count: number }) => b - a)
        .slice(0, 5) || [];
    const countries = sortedCountries.map((elem: { country: string }) => elem.country);
    const counts = sortedCountries.map((elem: { count: number }) => elem.count);
    const colors = getColorList(countries.length);
    return {
      data: {
        labels: countries,
        datasets: [
          {
            label: "# of Votes",
            data: counts,
            backgroundColor: colors,
            borderColor: colors,
            borderWidth: 1,
          },
        ],
      },
    };
  };


  return (
    <div ref={ref} style={{ padding: "20px" }}>
      <TitleContainer title="Dashboard">
        <div style={{ display: 'flex', justifyContent: 'space-between' }} className={style.filtersContainer}>
          <CampaignFilters setOption={(id: number) => setDashboardData(prev => ({
            ...prev,
            selectedCampaign: prev.campaigns[id],
          }))}>
            {dashboardData.campaigns.map((campaign, i) => (
              <option key={i} value={i}>
                {campaign.title}
              </option>
            ))}
          </CampaignFilters>
          <div style={{ display: 'flex', gap: '10px', alignItems: 'center' }}>
            <button
              onClick={() => {
                if (dashboardData.selectedCampaign && context?.getToken()) {
                  fetchCampaignData(dashboardData.selectedCampaign)
                }
              }}
              className={stylesButton.button}
              style={{ width: 'fit-content', gap: '5px' }}
            >
              <FontAwesomeIcon icon={faRefresh} /> Actualizar datos
            </button>
            <Button
              onClick={downloadReport}
              styleOverride={{ width: 'fit-content' }}
              text="Descargar reporte"
            />
          </div>
        </div>
        <Spacer space="50px"></Spacer>
        {!loading && (
          <div className={style.boxContainer}>
            {dashboardData.reportData.show && (
              <>
                {dashboardData.reportData.hasCommission && (
                  <InfoBox
                    title={"Recaudación total de la campaña"}
                    ammount={`${formatCurrency(
                      dashboardData.reportData.totalCampaign,
                      "es"
                    )}`}
                    elementId="totalCampaign"
                  />
                )}
                <InfoBox
                  title={dashboardData.reportData.hasCommission ? "Total recaudado por CKO" : "Total recaudado"}
                  ammount={`${formatCurrency(
                    dashboardData.reportData.hasCommission
                      ? dashboardData.reportData.totalCKO
                      : dashboardData.reportData.totalCampaign,
                    "es"
                  )}`}
                  elementId="totalCampaignCKO"
                />
                <InfoBox
                  title={"Cantidad de participantes"}
                  ammount={`${dashboardData.reportData.contributors}`}
                  elementId="contributors"
                />
                <InfoBox
                  title={"Cantidad de contribuciones"}
                  ammount={`${dashboardData.reportData.contributions}`}
                  elementId="contributions"
                />
              </>
            )}
          </div>
        )}
      </TitleContainer>
      <Spacer space="100px"></Spacer>

      {!loading && (
        dashboardData.selectedCampaign && dashboardData.campaignProgress && dashboardData.campaignProgress.response && (
          <>
            <TitleContainer title="Objetivo" fontSize="20px">
              <Spacer space="1rem"></Spacer>
              <Progress
                percentage={dashboardData.campaignProgress.percentage || 0}
                totalRaised={dashboardData.reportData.totalCampaign || 0}
                objectiveNumber={dashboardData.campaignProgress.objective || "$0"}
              ></Progress>
            </TitleContainer>

            <Spacer space="70px"></Spacer>
            <TitleContainer title="Gráficos" fontSize="20px">
              <div className={style.graphContainer}>
                <div className={style.pieContainer}>
                  <div style={{ minHeight: "470px", backgroundColor: "#333333" }} id="countriesGraph">
                    <h2 className={style.graphTitle}>Contribuciones por país</h2>
                    {dashboardData.graphData.contributorsCountries &&
                      dashboardData.graphData.contributorsCountries.data.datasets[0].data.length > 0 ? (
                      <PieGraph
                        data={dashboardData.graphData.contributorsCountries.data}
                      ></PieGraph>
                    ) : (
                      <PieGraph
                        data={{
                          labels: ["No hay datos disponibles aún"],
                          datasets: [
                            {
                              label: "No hay datos disponibles aún",
                              data: [1],
                              backgroundColor: ["#333333"],
                              borderColor: ["#3939384c"],
                              borderWidth: 1,
                            },
                          ],
                        }}
                      ></PieGraph>
                    )}
                  </div>

                  <div style={{ minHeight: "470px", backgroundColor: "#333333" }} id="convertionGraph">
                    <h2 className={style.graphTitle}>Tasa de conversión</h2>
                    {dashboardData.graphData.conversionRate &&
                      dashboardData.graphData.conversionRate.datasets[0].data[0] === 0 &&
                      dashboardData.graphData.conversionRate.datasets[0].data[1] === 0 ? (
                      <PieGraph
                        data={{
                          labels: ["No hay datos disponibles aún"],
                          datasets: [
                            {
                              label: "No hay datos disponibles aún",
                              data: [1],
                              backgroundColor: ["#333333"],
                              borderColor: ["#3939384c"],
                              borderWidth: 1,
                            },
                          ],
                        }}
                      />
                    ) : (
                      <PieGraph
                        data={
                          dashboardData.graphData.conversionRate || {
                            labels: [],
                            datasets: []
                          }
                        }
                      />
                    )}
                  </div>
                </div>

                <Spacer space="100px"></Spacer>
                <div className={style.barContainer}>
                  <div style={{ width: "80%" }}>
                    <h2 className={style.graphTitle}>Conversiones diarias</h2>
                    {dashboardData.graphData.sevenDaysConvertions.length !== 0 ? (
                      <BarGraph data={dashboardData.graphData.sevenDaysConvertions} />
                    ) : (
                      <h3 className={style.noContributors}>
                        Esta campaña no tuvo movimientos los últimos 7 dias para
                        generar éste gráfico.
                      </h3>
                    )}
                  </div>
                </div>
              </div>
            </TitleContainer>

            <Spacer space="100px"></Spacer>
            <TitleContainer title="Top 10 contribuyentes" fontSize="20px">
              {dashboardData.topContributors.length !== 0 ? (
                <TopContributors topContributors={dashboardData.topContributors} />
              ) : (
                <h3 className={style.noContributors}>
                  Esta campaña aún no tiene contribuyentes.
                </h3>
              )}
            </TitleContainer>
          </>
        )
      )}
      <Spacer space="100px"></Spacer>
    </div>
  );
}
