export const nonSpecialCharactersValidation = {
  value: new RegExp("^[a-zA-ZñÑ0-9áéíóúÁÉÍÓÚüÜ,.:;?!¡¿]+( [a-zA-ZñÑ0-9áéíóúÁÉÍÓÚüÜ,.:;?!¡¿]+)*(?!\s$)"),
  message: "Ingrese un valor válido",
};

export const isFutureDateValidation = (value: string | boolean) => {
  if (!value) return true;
  const dateValue = new Date(value as string);
  let minDate = new Date();

  if (dateValue.getFullYear() > 9999) {
    return false;
  }

  return minDate < dateValue;
};

export const emailValidation = {
  value: new RegExp("^[a-zA-Z0-9._+-]+@[a-zA-Z0-9.-]+.[a-zA-Z]{2,4}$"),
  message: "Ingrese un email válido",
};

export const validateNonEmpty = (value: number | string) => {
  if (value === "" || value === undefined) return false;
  return true; 
};