import Button from "../Button/Button";
import styles from "./ModalMessage.module.css";

interface Props {
  show: boolean;
  title: string;
  message: string;
  type: `${ModalTypes}`;
  onAccept: () => void;
  onCancel?: () => void;
}

enum ModalTypes {
  ERROR = "error",
  WARNING = "warning",
  ACCEPT = "accept",
  CONFIRM = "confirm",
}

function ModalMessage({
  show,
  title,
  message,
  type,
  onAccept,
  onCancel,
}: Props) {
  const handleAccept = () => {
    onAccept();
  };

  const hadnleCancel = () => {
    if (onCancel) onCancel();
  };

  return (
    <>
      {!show || (
        <div className={styles.container}>
          <div className={styles.modal}>
            <div className={styles.title}>{title}</div>
            <div className={styles.message}>{message}</div>
            <div className={styles.buttonContainer}>
              <Button text={type === "error" ? "Aceptar" : "Confirmar"} onClick={handleAccept}></Button>
              {type === "confirm" && (
                <Button text={"Cancelar"} onClick={hadnleCancel}></Button>
              )}
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export { ModalMessage, ModalTypes };
