import { useState } from "react";
import RotateLoader from "react-spinners/RotateLoader";
import styles from "./Loading.module.css";

interface Props {
  show: boolean;
}

export default function Loading({ show }: Props) {
  let [color] = useState("#ffd65c");

  return (
    <div className={styles.container}>
      <div className={styles.loading}>
        <RotateLoader color={color} loading={show} />
      </div>
    </div>
  );
}
