import styles from "./Tooltip.module.css";

export default function Tooltip({ tooltipMessage }: any) {
  
  return (
    <div className={styles.tooltip}>
      <span>?</span>
      <div>
        {tooltipMessage || ""}
      </div>
    </div>
  );
}