import { Bar } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

export interface GraphProps {
  data: ContributionData[];
}

export interface ContributionData {
  date: string;
  contributions: string;
}

export const BarGraph = ({ data }: GraphProps) => {
  const processedData = processData(data);

  return (
    <div
      style={{ width: "100%", height: "100%", maxHeight: "400px", backgroundColor: "#333333", borderRadius: "10px" }}
      id="barGraph"
    >
      <Bar
        data={processedData}
        options={{
          maintainAspectRatio: false,
          plugins: {
            legend: {
              position: "bottom",
              labels: {
                boxWidth: 20,
                color: "white",
              },
            },
          },
          datasets: {
            bar: {
              barThickness: "flex",
              maxBarThickness: 65,
            },
          },
          scales: {
            x: {
              grid: {
                display: false,
                borderColor: "white",
              },
              ticks: {
                color: "white",
              },
            },
            y: {
              min: 0,
              suggestedMin: 0,
              beginAtZero: true,
              grid: {
                display: false,
                borderColor: "white",
              },
              ticks: {
                stepSize: 1,
                color: "white",
              },
            },
          },
        }}
      />
    </div>
  );
};

const processData = (data: ContributionData[]) => {
  const labels: string[] = [];
  const contributions: number[] = [];

  const today = new Date();

  for (let i = 0; i < 8; i++) {
    // Comienza a partir de hoy. Ojo acá si cambiamos los días de la query.
    const currentDate = new Date(today);
    currentDate.setDate(today.getDate() - i);
    const formattedDate = currentDate.toISOString().slice(0, 10);

    // Acá verifico si hay datos disponibles para el día que se esta recorriendo en este for
    const contributionData = data.find(
      (item) => item.date.slice(0, 10) === formattedDate
    );

    // Acá agrego la fecha formateada como etiqueta
    labels.unshift(formattedDate);

    // Si el objeto que llega tiene datos disponibles para el día actual, agrego las contribuciones
    if (contributionData) {
      contributions.unshift(parseInt(contributionData.contributions));
    } else {
      // Si no tengo datos para el día actual, agrego 0 contribuciones
      contributions.unshift(0);
    }
  }

  return {
    labels,
    datasets: [
      {
        label: "Contribuciones",
        data: contributions,
        backgroundColor: "rgba(15, 79, 120, 1)",
      },
    ],
  };
};
