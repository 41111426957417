import { ChangeEvent, useEffect, useRef, useState } from "react";
import { FormProvider, SubmitHandler, useForm } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";
import { SyncLoader } from "react-spinners";
import Switch from "react-switch";
import Button from "../../components/Button/Button";
import { ModalMessage } from "../../components/ModalMessage/ModalMessage";
import AddRewardSelector from "../../components/pages/ProductRewards/AddRewardSelector";
import ProductsRewardsTable from "../../components/pages/ProductRewards/ProductsRewardsTable";
import styles from "../../components/ProductsContainer/ProductsContainer.module.css";
import TextCombo from "../../components/TextCombo/TextCombo";
import TextField from "../../components/TextField/TextField";
import TitleContainer from "../../components/TitleContainer/TitleContainer";
import { useGet } from "../../hooks/useGet";
import { createProduct, updateProduct } from "../../services/CampaignServices";
import { nonSpecialCharactersValidation } from "../../utils/formValidations.utils";
import { ProductRewardsResponse } from "../../components/pages/ProductRewards/product-reward.interface";
import { useGetActiveRewards } from "../../components/pages/ProductRewards/useGetActiveRewards";
import { formatCurrency } from "../../utils/format.utils";
import CurrencyInput from "../../components/CurrencyInput/CurrencyInput";

type FormData = {
  name: string;
  description: string;
  price: number | string;
  type: string;
  chances: number;
  stock: number;
  imageSrc: string;
};

const errorMessages = {
  default: "Ha ocurrido un error, por favor intentelo nuevamente.",
  noRewards: "Debe agregar al menos una recompensa.",
};

export default function Product() {
  const { getData, data } = useGet<any>();
  const { key, uuid } = useParams();
  const [file, setFile] = useState<File>();
  const [imageSrc, setImageSrc] = useState("");
  const previewImage = useRef<HTMLImageElement>(null);
  const [showModal, setShowModal] = useState(false);
  const [showError, setShowError] = useState(false);
  const [loading, setLoading] = useState(false);
  const [productRewards, setProductRewards] = useState<
    ProductRewardsResponse[]
  >([]);
  let navigate = useNavigate();
  const { activeRewards, setRewards } = useGetActiveRewards();

  const formMethods = useForm<FormData>({
    defaultValues: {
      name: "",
      description: "",
      price: 0,
      type: "",
      chances: 0,
      stock: 0,
    },
  });
  const { handleSubmit, control, reset, watch } = formMethods;

  const [active, setActive] = useState(true);
  const handleActiveChange = (checked: boolean) => {
    setActive(checked);
  };

  const [errorType, setErrorType] = useState<keyof typeof errorMessages | null>(null);

  useEffect(() => {
    if (uuid !== "new") getData(`campaigns/${key}/product/${uuid}`);
  }, []);

  useEffect(() => {
    if (!data) return;
    reset({
      name: data?.name,
      description: data?.description,
      price: formatCurrency(data?.price, "es") || "",
      type: data?.type,
      chances: data?.chances,
      stock: data?.stock,
    });
    setActive(data?.active);

    if (data.productRewards) setProductRewards(data.productRewards);
    if (data?.imageSrc) setImageSrc(data.imageSrc);
    if (data.productRewards) {
      const productRewardIds = data.productRewards.map((reward: ProductRewardsResponse) => reward.id);
      setRewards(activeRewards.filter((reward) => !productRewardIds.includes(reward.id)));
    }
    return;
  }, [data]);

  const onSubmit: SubmitHandler<FormData> = (data: FormData, e: any) => {
    e.preventDefault();

    if (productRewards.length === 0) {
      setShowError(true);
      setShowModal(true);
      setErrorType("noRewards");
      return;
    }

    addProduct(data);
  };

  const addProduct = async (data: any) => {
    if (!key) return;
    const { name, description, price, type, chances, stock } = data;

    const formData = new FormData();
    const plainPrice = price.replace(/[^0-9]/g, "");
    if (file) formData.append("file", file);
    formData.append("name", name);
    formData.append("description", description);
    formData.append("price", plainPrice);
    formData.append("type", type);
    formData.append("stock", stock || 0);
    formData.append("chances", chances || 0);
    formData.append("active", active.toString());
    productRewards.forEach((reward) =>
      formData.append("rewards[]", reward.id.toString())
    );

    try {
      setLoading(true);

      const response =
        uuid === "new"
          ? await createProduct(key, formData)
          : await updateProduct(key, uuid ?? "", formData);

      if (response) {
        setShowError(false);
        setShowModal(true);
      }
    } catch (error) {
      setShowError(true);
      setShowModal(true);
      setErrorType("default");
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  const handleFileChange = (e: ChangeEvent<HTMLInputElement>) => {
    if (e.target.files) {
      setFile(e.target.files[0]);
      setImageSrc(URL.createObjectURL(e.target.files[0]));
    }
  };

  const onAcceptModal = () => {
    setShowModal(false);
    if (showError) {
      setShowModal(false);
    } else {
      navigate(`/campaigns/${key}`);
    }
  };

  const showStockField = () => {
    return watch("type") === "product";
  };

  const handleAddReward = (reward: ProductRewardsResponse) => {
    setProductRewards([...productRewards, reward]);
  };

  function handleDeleteReward(id: string) {
    const deletedReward = productRewards.find((reward) => reward.id === Number(id));
    const updatedProductRewards = productRewards.filter(
      (reward) => reward.id !== Number(id)
    );
    setProductRewards(updatedProductRewards);

    if (deletedReward) {
      setRewards([...activeRewards, deletedReward]);
    }
  }

  return (
    <>
      <ModalMessage
        show={showModal}
        title={showError ? "Error" : `Producto ${uuid === "new" ? "creado" : "editado"}`}
        message={showError && errorType ? errorMessages[errorType] : `El producto se ha ${uuid === "new" ? "creado" : "editado"} correctamente.`}
        type={showError ? "error" : "accept"}
        onAccept={() => onAcceptModal()}
      />
      <TitleContainer title={"Producto"} backURL={`/campaigns/${key}`}>
        <fieldset className={styles.fieldset}>
          <legend>
            {uuid === "new" ? "Crear nuevo producto" : "Editar producto"}
          </legend>
          <FormProvider {...formMethods}>
          <form onSubmit={handleSubmit(onSubmit)}>
            <TextField
              label="Nombre del producto"
              props={{
                name: "name",
                control,
                rules: {
                  required: true,
                  pattern: nonSpecialCharactersValidation,
                },
              }}
              type="input"
            />
            <TextField
              label="Descripción"
              props={{
                name: "description",
                control,
                rules: {
                  required: false,
                  pattern: nonSpecialCharactersValidation,
                },
              }}
              type="text"
            />
            <label
              style={{ display: "flex", alignItems: "center", gap: "10px" }}
            >
              <span
                style={{
                  color: "#f2c233",
                  fontSize: "1rem",
                  fontFamily: "Roboto",
                  fontWeight: 600,
                }}
              >
                Activo
              </span>
              <Switch
                onChange={handleActiveChange}
                checked={active}
                className={styles.reactSwitch}
              />
            </label>
            <TextCombo
              label="Tipo"
              props={{
                control,
                name: "type",
                rules: { required: true },
              }}
            >
              <option value={""} disabled>
                Selecciona
              </option>
              <option value={"contribution"}>Contribución</option>
              <option value={"product"}>Producto</option>
            </TextCombo>
            <CurrencyInput
              label="Precio"
              props={{
                name: "price",
                control,
                rules: {
                  required: true,
                },
              }}
            />
            <TextField
              label="Chances"
              props={{
                name: "chances",
                control,
                rules: {
                  min: {
                    value: 0,
                    message: "Ingrese un valor mayor o igual a cero",
                  },
                },
              }}
              type="number"
            />
            {showStockField() && (
              <TextField
                label="Stock"
                props={{
                  name: "stock",
                  control,
                  rules: {
                    min: {
                      value: 0,
                      message: "Ingrese un valor mayor o igual a cero",
                    },
                  },
                }}
                type="number"
              />
            )}

            <div className={styles.fileInput}>
              {imageSrc ? (
                <img
                  id="product"
                  ref={previewImage}
                  src={imageSrc}
                  alt="product"
                  width={200}
                />
              ) : (
                <div></div>
              )}
              <input
                type="file"
                name="file"
                id="file"
                disabled={loading}
                onChange={handleFileChange}
                accept="image/*"
                required={uuid === "new" ? true : false}
              />
              <label
                htmlFor="file"
                style={{
                  cursor:
                    loading || data?.type === "contribution"
                      ? "not-allowed"
                      : "pointer",
                }}
              >
                Subir imagen
              </label>
            </div>

            <div style={{ gridColumn: "1/3" }}>
              <AddRewardSelector
                activeRewards={activeRewards}
                setRewards={setRewards}
                onRewardSelected={handleAddReward}
              />
              <ProductsRewardsTable
                productRewards={productRewards}
                onDeleteReward={handleDeleteReward}
              />
            </div>

            <div className={styles.boxButtonsAddProductForm}>
              {!loading && (
                <>
                  <Button text={"Aceptar"} type="submit" />
                  <Button
                    text={"Cancelar"}
                    onClick={() => navigate(`/campaigns/${key}`)}
                  />
                </>
              )}
              <SyncLoader color="#f2c233" loading={loading} size={15} />
            </div>
          </form>
          </ FormProvider>
        </fieldset>
      </TitleContainer>
    </>
  );
}
