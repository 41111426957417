import styles from './CampaignInfoBox.module.css';
import React from 'react'
import { ReactComponent as Award } from '../../images/award.svg';

export default function CampaignInfoBox({ campaign, award }: { campaign: string, award: string }) {
  return (
    <div className={styles.containerInfoBox}>
      <h2>{campaign}</h2>
      <div>
        <p>
        <Award></Award>
         &nbsp; Esta campaña otorga <span>{award} premios </span>
        </p>
      </div>
    </div>
  )
}
