import { ChangeEvent, useContext, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import Button from "../../components/Button/Button";
import Spacer from "../../components/Spacer/Spacer";
import TextCombo from "../../components/TextCombo/TextCombo";
import TitleContainer from "../../components/TitleContainer/TitleContainer";
import styles from "./TemplateDB.module.css";
import FileInput from "../../components/FileInput/FileInput";
import { getTemplateDataBase, uploadMembersDataBase } from "../../services/ClientServices";
import { SyncLoader } from "react-spinners";
import ExcelViewer from "../../components/ExcelViewer/ExcelViewer";
import { Context } from "../../App";

type FormData = {
    clientKey: string;
};

export default function TemplateDB() {

    const { control, setValue } = useForm<FormData>();
    const [clientsKeys, setClientsKeys] = useState<any[]>([]);
    const [file, setFile] = useState<File | undefined>();
    const [fileUrl, setFileUrl] = useState<string | undefined>();
    const [clientSelected, setClientSelected] = useState<string | undefined>();
    const [loading, setLoading] = useState(false);
    const [isXLSX, setIsXLSX] = useState<boolean | null>(false);
    const context = useContext(Context);

    useEffect(() => {
        const clientsKeys = localStorage.getItem("clientsKeys") || "";
        setIsXLSX(false);
        if (clientsKeys) {
            setClientsKeys(JSON.parse(clientsKeys));
            setValue("clientKey", JSON.parse(clientsKeys)[0].key);
            setClientSelected(JSON.parse(clientsKeys)[0].key);
        }
    }, []);

    useEffect(() => {
        if (file) {
            const extension = file.name.split(".").pop();
            setIsXLSX(extension === "xlsx");
        }
    }, [file]);

    const handleFileChange = (e: ChangeEvent<HTMLInputElement>) => {
        if (e.target.files && e.target.files.length > 0) {
            setFile(e.target.files[0]);
            setFileUrl(URL.createObjectURL(e.target.files[0]) || "");
        } else {
            setFileUrl(undefined)
        }
    };

    const uploadDocument = () => {
        if (!file || !isXLSX) return;

        const formData = new FormData();
        formData.append("file", file);
        setLoading(true);

        uploadMembersDataBase(clientSelected, formData)
            .then(() => {
                context?.showAcceptModal(
                    "Éxito",
                    "Usuarios cargados correctamente",
                    () => { }
                );
            })
            .catch(() => {
                context?.showErrorModal(
                    "Ha ocurrido un error al guardar los usuarios",
                    "Por favor, recuerde subir un archivo .xlsx e intentelo nuevamente. Asegúrese de que el archivo tenga el formato correcto."
                );
            })
            .finally(() => {
                setLoading(false);
            });
    };

    const handleDownloadTemplate = async () => {
        if (clientsKeys) {
            getTemplateDataBase().catch((error) => {
                console.error(error);
            })
        }
    };

    const handleClientChange = (e: ChangeEvent<HTMLSelectElement>) => {
        setValue("clientKey", e.target.value);
        setClientSelected(e.target.value);
    };

    return (
        <TitleContainer title="Plantilla para base de datos">
            <TextCombo
                label="Cliente"
                props={{
                    control,
                    name: "clientKey",
                    onChange: handleClientChange,
                }}
            >
                {clientsKeys.map((client, index) => (
                    <option key={index} value={client.key}>
                        {client.description}
                    </option>
                ))}
            </TextCombo>
            <Spacer space="50px"></Spacer>
            <Button
                disabled={false}
                text={"Descargar plantilla"}
                styleOverride={{ margin: "auto" }}
                onClick={handleDownloadTemplate}
            />
            <Spacer space="50px"></Spacer>
            <fieldset className={styles.fieldset}>
                <legend>Carga de plantilla</legend>
                <div
                    style={{
                        display: "flex",
                        justifyContent: "space-evenly",
                        gap: "2rem",
                    }}
                >
                    <FileInput
                        handleFileChange={handleFileChange}
                        inputName="clientsDataBase"
                        label={file ? "Reemplazar archivo .xlsx" : "Subir archivo .xlsx"}
                        accept=".xlsx"
                    />
                    {!loading &&
                        <Button
                            text={"Subir plantilla"}
                            disabled={!file || !isXLSX}
                            onClick={uploadDocument}
                            styleOverride={{ width: "200px" }}
                        />
                    }
                    <SyncLoader
                        color="#f2c233"
                        loading={loading}
                        size={15}
                    />
                </div>
                <Spacer space="50px"></Spacer>
                <div className={styles.pdfContainer}>
                    {file && isXLSX && <ExcelViewer file={file} />}
                    {file && !isXLSX && (
                        <div style={{ display: "flex", justifyContent: "center" }}>
                            <div style={{ backgroundColor: "#FCE8DB", borderRadius: "10px", width: "fit-content" }}>
                                <p style={{ color: "#71192F", fontSize: "1rem", fontWeight: "500", textAlign: "center", padding: "1rem" }}>
                                    El archivo seleccionado no es un archivo .xlsx
                                </p>
                            </div>
                        </div>
                    )}
                </div>
            </fieldset>
        </TitleContainer>
    )
}
