import { SubmitHandler, useForm } from "react-hook-form";
import Button from "../../components/Button/Button";
import TextCombo from "../../components/TextCombo/TextCombo";
import TextField from "../../components/TextField/TextField";
import TitleContainer from "../../components/TitleContainer/TitleContainer";
import { usePost } from "../../hooks/usePost";
import {
  emailValidation,
  nonSpecialCharactersValidation,
} from "../../utils/formValidations.utils";
import { useGetClients } from "../Clients/useGetClients";
import styles from "./Users.module.css";
import { UserCreateDto } from "./users.types";
import { useNavigate } from "react-router-dom";

type UserFormData = {
  email: string;
  name: string;
  lastName: string;
  clientKey: string;
  role: string;
};

export default function NewUser() {
  const { postData, isLoading, error } = usePost<UserCreateDto, any>();
  const navigate = useNavigate();
  const { clients } = useGetClients();

  const { handleSubmit, control } = useForm<UserFormData>({
    defaultValues: {
      email: "",
      name: "",
      lastName: "",
      clientKey: "",
      role: "reader",
    },
  });

  const onSubmit: SubmitHandler<UserFormData> = async (
    data: UserFormData,
    e: any
  ) => {
    e.preventDefault();

    const { email, name, lastName, clientKey, role } = data;
    postData("auth/client-admin", {
      email,
      name,
      lastName,
      client: clientKey,
      role,
    }).then((_response) => {
      navigate(-1);
    });
  };

  if (isLoading || !clients) {
    return <p>Loading...</p>;
  }
  if (error) {
    return <p>Error: {error}</p>;
  }
  return (
    <TitleContainer title="Nuevo Usuario" backURL="/users">
      <fieldset className={styles.fieldset}>
        <legend>Crear nuevo usuario</legend>
        <form onSubmit={handleSubmit(onSubmit)} className={styles.form}>
          <TextCombo
            label="Cliente"
            props={{
              control,
              name: "clientKey",
              rules: { required: true },
            }}
          >
            <option selected hidden>
              Seleccione un cliente
            </option>
            {clients.map((client, index) => (
              <option key={index} value={client.key}>
                {client.title}
              </option>
            ))}
          </TextCombo>
          <TextField
            label="Email"
            props={{
              name: "email",
              control,
              rules: {
                required: true,
                pattern: emailValidation,
              },
            }}
          />
          <TextField
            label="Nombre"
            props={{
              name: "name",
              control,
              rules: {
                required: true,
                pattern: nonSpecialCharactersValidation,
              },
            }}
          />
          <TextField
            label="Apellido"
            props={{
              name: "lastName",
              control,
              rules: {
                required: true,
                pattern: nonSpecialCharactersValidation,
              },
            }}
          />

          <TextCombo
            label="Rol"
            props={{
              control,
              name: "role",
            }}
          >
            <option key={1} value="reader">
              Lector
            </option>
            <option key={2} value="adminClient">
              Cliente administrador
            </option>
            <option key={3} value="adminCKO">
              Administrador CKO
            </option>
          </TextCombo>

          <Button text="Crear usuario" type="submit" />
        </form>
      </fieldset>
    </TitleContainer>
  );
}
