import html2canvas from "html2canvas";

function takeScreenshot(node: any, options = {}) {
  if (!node) {
    return;
  }
  return html2canvas(node, { scale: 3 })
    .then((canvas) => {
      const croppedCanvas = document.createElement("canvas");
      const croppedCanvasContext = croppedCanvas.getContext("2d");

      if (!croppedCanvasContext) return;
      // init data
      const cropPositionTop = 0;
      const cropPositionLeft = 0;
      const cropWidth = canvas.width;
      const cropHeight = canvas.height;

      croppedCanvas.width = cropWidth;
      croppedCanvas.height = cropHeight;

      croppedCanvasContext.drawImage(canvas, cropPositionLeft, cropPositionTop);

      const base64Image = croppedCanvas.toDataURL();
      return base64Image;
    })
    .catch((err) => {
      return err;
    });
}

function getColorList(numberOfColors: number) {
  const maxNumber = numberOfColors > 5 ? 5 : numberOfColors;

  var colors = [
    "#f2c233",
    "rgba(15, 79, 120, 1)",
    "rgba(46, 137, 195, 1)",
    "rgba(221, 173, 89, 1)",
    "rgba(151,115,50, 1)",
  ];

  return colors.slice(0, maxNumber);
}

function fallbackCopyTextToClipboard(text: string) {
  var textArea = document.createElement("textarea");
  textArea.value = text;
  
  // Avoid scrolling to bottom
  textArea.style.top = "0";
  textArea.style.left = "0";
  textArea.style.position = "fixed";

  document.body.appendChild(textArea);
  textArea.focus();
  textArea.select();

  try {
    var successful = document.execCommand('copy');
    var msg = successful ? 'successful' : 'unsuccessful';
    console.log('Fallback: Copying text command was ' + msg);
  } catch (err) {
    console.error('Fallback: Oops, unable to copy', err);
  }

  document.body.removeChild(textArea);
}
function copyTextToClipboard(text: string) {
  if (!navigator.clipboard) {
    fallbackCopyTextToClipboard(text);
    return;
  }
  navigator.clipboard.writeText(text).then(function() {
    console.log('Async: Copying to clipboard was successful!');
  }, function(err) {
    console.error('Async: Could not copy text: ', err);
  });
}

export { takeScreenshot, getColorList, copyTextToClipboard };
