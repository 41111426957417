import { ChangeEvent, useEffect, useRef, useState } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import { useLocation, useNavigate } from "react-router-dom";
import Switch from "react-switch";
import Button from "../../components/Button/Button";
import { ModalMessage } from "../../components/ModalMessage/ModalMessage";
import TextField from "../../components/TextField/TextField";
import TitleContainer from "../../components/TitleContainer/TitleContainer";
import {
  addRewardTemplate,
  updateRewardTemplate,
} from "../../services/CampaignServices";
import { nonSpecialCharactersValidation } from "../../utils/formValidations.utils";
import styles from "./Campaign.module.css";
import FieldsetLayout from "../../components/FieldsetLayout/FieldsetLayout";

type FormData = {
  title: string;
};

interface LocationState {
  rewardTemplate: {
    active: boolean;
    id: number;
    title: string;
    url: string;
    uuid: string;
  };
  campaignKey: string;
}

export default function Reward() {
  const location = useLocation();
  const { rewardTemplate, campaignKey } = location.state as LocationState;

  const { handleSubmit, control } = useForm<FormData>({
    defaultValues: {
      title: rewardTemplate?.title || "",
    },
  });

  const [file, setFile] = useState<File>();
  const [imageSrc, setImageSrc] = useState(rewardTemplate?.url || "");
  const previewImage = useRef<HTMLImageElement>(null);
  let navigate = useNavigate();
  const [showModal, setShowModal] = useState(false);
  const [showError, setShowError] = useState(false);

  const [status, setStatus] = useState<boolean>(true);
  const handleChange = (checked: boolean) => {
    setStatus(checked);
  };

  useEffect(() => {
    if (rewardTemplate) {
      setStatus(rewardTemplate.active);
    }
  }, [rewardTemplate]);

  const onSubmit: SubmitHandler<FormData> = async (data: FormData, e: any) => {
    e.preventDefault();
    try {
      const formData = new FormData();
      if (file) formData.append("file", file);

      if (rewardTemplate?.uuid) {
        formData.append("active", status.toString());
        await updateRewardTemplate(campaignKey, rewardTemplate.uuid, formData);
      } else {
        formData.append("title", data.title);
        await addRewardTemplate(campaignKey, formData);
      }
      setShowModal(true);
      setShowError(false);
    } catch (e) {
      setShowError(true);
    }
  };

  const handleFileChange = (e: ChangeEvent<HTMLInputElement>) => {
    if (e.target.files) {
      setFile(e.target.files[0]);
      setImageSrc(URL.createObjectURL(e.target.files[0]));
    }
  };

  return (
    <>
      {showModal && (
        <ModalMessage
          show={showModal}
          title={showError ? "Error" : "Éxito"}
          message={
            showError
              ? "Ha ocurrido un error, por favor intentelo nuevamente."
              : "Cambios guardados correctamente"
          }
          type={showError ? "error" : "accept"}
          onAccept={() => navigate(`/campaigns/${campaignKey}`)}
        />
      )}
      <TitleContainer title={"Plantilla"} backURL={`/campaigns/${campaignKey}`}>
        <FieldsetLayout
          label={rewardTemplate ? "Editar plantilla" : "Crear plantilla"}
        >
          <form
            onSubmit={handleSubmit(onSubmit)}
            className={styles.rewardContainer}
          >
            <div
              style={{ display: "flex", flexDirection: "column", gap: "20px" }}
            >
              <TextField
                label="Título"
                props={{
                  name: "title",
                  control,
                  rules: {
                    required: true,
                    pattern: nonSpecialCharactersValidation,
                  },
                }}
                type="input"
              />
              <label
                style={{
                  display: "flex",
                  alignItems: "center",
                  gap: "10px",
                  width: "fit-content",
                }}
              >
                <span
                  style={{
                    color: "#f2c233",
                    fontSize: "1rem",
                    fontFamily: "Roboto",
                    fontWeight: 600,
                  }}
                >
                  Activo
                </span>
                <Switch
                  onChange={handleChange}
                  checked={status}
                  className={styles.reactSwitch}
                />
              </label>
            </div>
            <div className={styles.fileInput}>
              {imageSrc ? (
                <img
                  id="product"
                  ref={previewImage}
                  src={imageSrc}
                  alt="product"
                />
              ) : (
                <div></div>
              )}
              <input
                type="file"
                name="file"
                id="file"
                onChange={handleFileChange}
                accept="image/*"
              />
              <label htmlFor="file">Subir imagen</label>
            </div>
            <div className={styles.btns}>
              <Button text={"Aceptar"} type="submit" />
              <Button
                text={"Cancelar"}
                onClick={() => navigate(`/campaigns/${campaignKey}`)}
              />
            </div>
          </form>
        </FieldsetLayout>
      </TitleContainer>
    </>
  );
}
