import { useState } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import Switch from "react-switch";
import Button from "../../components/Button/Button";
import FieldsetLayout from "../../components/FieldsetLayout/FieldsetLayout";
import styles from "../../components/Table/Table.module.css";
import TextField from "../../components/TextField/TextField";
import TitleContainer from "../../components/TitleContainer/TitleContainer";
import { usePost } from "../../hooks/usePost";
import { nonSpecialCharactersValidation } from "../../utils/formValidations.utils";
import rewardStyles from "./ProductRewards.module.css";
import { CreateProductRewardDto } from "./product.types";
import Tooltip from "../../components/Tooltip/Tooltip";

type UserFormData = {
  title: string;
  requiresShipping: boolean;
  uniqueCode: string;
};

export default function NewProductReward() {
  const { postData, isLoading, error } = usePost<CreateProductRewardDto, any>();
  const navigate = useNavigate();

  const { handleSubmit, control, setValue } = useForm<UserFormData>({
    defaultValues: {
      title: "",
      uniqueCode: "",
    },
  });

  const [requiresShipping, setRequiresShipping] = useState<boolean>(false);
  const handleRequiresShipping = (checked: boolean) => {
    setRequiresShipping(checked);
    if (checked) handleUniqueCode(false);
  };

  const [uniqueCode, setUniqueCode] = useState<boolean>(false);
  const handleUniqueCode = (checked: boolean) => {
    setUniqueCode(checked);
    if (!checked) setValue("uniqueCode", "");
    if (checked) handleRequiresShipping(false);
  };

  const onSubmit: SubmitHandler<UserFormData> = async (
    data: UserFormData,
    e: any
  ) => {
    e.preventDefault();

    const { title, uniqueCode } = data;
    postData("products/reward", {
      title,
      requiresShipping,
      uniqueCode,
    }).then((_response) => {
      navigate(-1);
    });
  };

  if (isLoading) {
    return <p>Loading...</p>;
  }
  if (error) {
    return <p>Error: {error}</p>;
  }
  return (
    <TitleContainer title="Recompensa" backURL="/rewards">
      <FieldsetLayout label="Nueva recompensa">
        <form
          onSubmit={handleSubmit(onSubmit)}
          style={{ display: "flex", flexDirection: "column", gap: "1rem" }}
        >
          <TextField
            label="Título"
            props={{
              name: "title",
              control,
              rules: {
                required: true,
                pattern: nonSpecialCharactersValidation,
              },
            }}
          />
          <div className={rewardStyles.switchContainer}>
            <span>¿Tiene entrega a domicilio?</span>
            <Switch
              onChange={handleRequiresShipping}
              checked={requiresShipping}
              className={styles.reactSwitch}
            />
            <Tooltip tooltipMessage="Este campo no se podrá modificar una vez creada la recompensa."/>
          </div>

          <div className={rewardStyles.switchContainer}>
            <span>Código único</span>
            <Switch
              onChange={handleUniqueCode}
              checked={uniqueCode}
              className={styles.reactSwitch}
            />
            <Tooltip tooltipMessage="Este campo no se podrá modificar una vez creada la recompensa."/>
            {uniqueCode && (
              <TextField
                placeholder="Ingrese código"
                row
                props={{
                  name: "uniqueCode",
                  control,
                  rules: {
                    required: true,
                  },
                }}
              />
            )}
          </div>

          <Button
            text="Crear recompensa"
            type="submit"
            styleOverride={{ margin: "0 auto" }}
          />
        </form>
      </FieldsetLayout>
    </TitleContainer>
  );
}
