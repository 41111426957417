import React, { useContext, useEffect, useState } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import { SyncLoader } from "react-spinners";
import Switch from "react-switch";
import { Context } from "../../App";
import { usePost } from "../../hooks/usePost";
import { ReactComponent as Open } from "../../images/openInTab.svg";
import { ReactComponent as Copy } from "../../images/copy.svg";
import { CampaignDetailedResponse } from "../../pages/Campaign/campaign.types";
import { getCampaigns } from "../../services/DashboardServices";
import Button from "../Button/Button";
import FieldsetLayout from "../FieldsetLayout/FieldsetLayout";
import TextCombo from "../TextCombo/TextCombo";
import TextField from "../TextField/TextField";
import Tooltip from "../Tooltip/Tooltip";
import styles from "./PaymentCredentials.module.css";
import { copyTextToClipboard } from "../../utils/utils";

type FormData = {
  clientId?: string;
  accessToken?: string;
  clientSecret?: string;
  redirectUrl?: string;
  mercadoPagoOauthUrl?: string;
  copyFromCampaign?: string;
  commission?: number | string;
};

type Props = {
  campaign: CampaignDetailedResponse;
  setHasCredentials: (hasCredentials: boolean) => void;
};

export default function PaymentCredentials({
  campaign,
  setHasCredentials,
}: Props) {
  let context = useContext(Context);

  const { mercadoPagoOauthUrl, redirectUrl, credentials, commission } =
    campaign;

  const { control, getValues, handleSubmit, reset, setValue } =
    useForm<FormData>({
      defaultValues: {
        clientId: "",
        accessToken: "",
        clientSecret: "",
        redirectUrl: "",
        mercadoPagoOauthUrl: "",
        copyFromCampaign: "",
        commission: "",
      },
    });
  const [copyFromAnotherCampaign, setCopyFromAnotherCampaign] =
    useState<boolean>(false);
  const handleCopyFromAnotherCampaign = (checked: boolean) => {
    setCopyFromAnotherCampaign(checked);
  };

  const [hasComission, setHasComission] = useState(false);
  const handleTogglecommission = (checked: boolean) => {
    setHasComission(checked);
    if (checked) setValue("commission", "");
  };
  const handleCommissionChanged = (e: React.FormEvent<HTMLInputElement>) => {
    const value = e.currentTarget.value;
    if (value === "0") handleTogglecommission(false);
  };

  const [userCanSetCredentials, setUserCanSetCredentials] =
    useState<boolean>(false);
  const [credentialsConfigured, setCredentialsConfigured] = useState(true);

  useEffect(() => {
    const userRole = context?.getUserData()?.role;
    setUserCanSetCredentials(userRole === "adminCKO");
    const commissionCredentials =
      !!mercadoPagoOauthUrl && !!redirectUrl && commission !== "0.00";
    const nonCommissionCredentials =
      !!credentials &&
      commission === "0.00" &&
      !mercadoPagoOauthUrl &&
      !redirectUrl;
    setCredentialsConfigured(commissionCredentials || nonCommissionCredentials);
  }, [campaign]);

  useEffect(() => {
    if (!credentialsConfigured) {
      setCopyFromAnotherCampaign(false);
      return;
    }
    reset({
      redirectUrl,
      mercadoPagoOauthUrl,
      commission: Number(commission) * 100,
    });
    setHasComission(!!Number(commission));
    setCopyFromAnotherCampaign(false);
    setHasCredentials(true);
  }, [credentialsConfigured]);

  const onSubmit: SubmitHandler<FormData> = (formData: FormData, e: any) => {
    e.preventDefault();
    context?.showConfirmModal(
      "Revise los datos cargados",
      "Esta configuración no podrá cambiarse más adelante",
      createCampaignCredentials
    );
  };

  const { postData, isLoading, error } = usePost();
  const createCampaignCredentials = () => {
    let data = getValues();
    const {
      clientId,
      accessToken,
      copyFromCampaign,
      clientSecret,
      commission,
    } = data;
    const commissionValue = hasComission ? Number(commission) : 0;
    postData(
      `campaigns/${campaign.key}/mercado-pago-credentials`,
      copyFromAnotherCampaign
        ? { copyFromCampaign, commission: commissionValue }
        : {
            applicationId: clientId,
            accessToken,
            secretKey: clientSecret,
            commission: commissionValue,
          }
    )
      .then(
        () => (
          context?.showAcceptModal(
            "Éxito",
            "Cambios guardados correctamente",
            () => window.location.reload()
          ),
          setHasCredentials(true)
        )
      )
      .finally(() => {
        if (error) {
          context?.showErrorModal(
            "Error",
            "Ha ocurrido un error al guardar los cambios"
          );
        }
      });
  };

  const [campaigns, setCampaigns] = useState<any>([]);
  const [filteredCampaigns, setFilteredCampaigns] = useState<any>([]);
  useEffect(() => {
    getCampaigns()
      .then((response) => {
        if (response) {
          setCampaigns(response);
        }
      })
      .catch((error) => console.error(error));
  }, []);

  useEffect(() => {
    let availableCampaigns;
    if (hasComission) {
      availableCampaigns = campaigns.filter(
        (camp: CampaignDetailedResponse) =>
          camp.active &&
          camp.client.key === campaign.client.key &&
          Number(camp.commission) !== 0
      );
    } else {
      availableCampaigns = campaigns.filter(
        (camp: CampaignDetailedResponse) =>
          camp.active &&
          camp.client.key === campaign.client.key &&
          Number(camp.commission) === 0
      );
    }

    setFilteredCampaigns(availableCampaigns);
  }, [hasComission, campaigns]);

  const openUrlInTab = (url: string) => {
    window.open(url, "_blank", "noreferrer");
  };

  if (!userCanSetCredentials) {
    return (
      <>
        <FieldsetLayout label="Credenciales de pago" showCheck checked>
          <>
            {credentialsConfigured
              ? "Credenciales ya configuradas por administrador"
              : "Credenciales aún no generadas por administrador de CKO."}
          </>
        </FieldsetLayout>
      </>
    );
  }

  if (credentialsConfigured && !hasComission) {
    return (
      <>
        <FieldsetLayout label="Credenciales de pago" showCheck checked>
          <div className={styles.label}>
            Credenciales configuradas sin split de comisión
          </div>
        </FieldsetLayout>
      </>
    );
  }

  return (
    <FieldsetLayout
      label="Credenciales de pago"
      showCheck
      checked={credentialsConfigured}
    >
      <form className={styles.container} onSubmit={handleSubmit(onSubmit)}>
        <div className={styles.commissionSwitch}>
          <div>
            <label className={styles.label}>
              <span>Con split de comisión</span>
            </label>
            <Switch
              onChange={handleTogglecommission}
              checked={hasComission}
              className={styles.reactSwitch}
              disabled={credentialsConfigured}
            />
            <Tooltip tooltipMessage="Al utilizar credenciales de otra campaña el dinero recaudado se acumulará en mercado pago en la campaña seleccionada. " />
          </div>
          {hasComission && (
            <TextField
              label="Porcentaje (%)"
              row
              props={{
                name: "commission",
                control,
                rules: {
                  min: {
                    value: 1,
                    message: "Ingrese un valor entre 1 y 100",
                  },
                  max: {
                    value: 100,
                    message: "Ingrese un valor entre 1 y 100",
                  },
                  required: true,
                },
              }}
              readOnly={credentialsConfigured}
              tooltipMessage={
                "La comisión tiene que ser un número entero entre 1% y 100%, sin colocar el símbolo porcentual (%)."
              }
              type="number"
              inputProps={{
                onInput: handleCommissionChanged,
              }}
            />
          )}
        </div>

        {!credentialsConfigured && (
          <>
            <div className={styles.switchContainer}>
              <div>
                <label className={styles.label}>
                  <span>Usar credenciales de otra campaña</span>
                </label>
                <Switch
                  onChange={handleCopyFromAnotherCampaign}
                  checked={copyFromAnotherCampaign}
                  className={styles.reactSwitch}
                  disabled={false}
                />
                <Tooltip tooltipMessage="Al utilizar credenciales de otra campaña el dinero recaudado se acumulará en mercado pago en la campaña seleccionada. " />
              </div>
              {!isLoading && (
                <Button
                  text={"Guardar cambios"}
                  type="submit"
                  styleOverride={{ width: "200px" }}
                />
              )}
              <SyncLoader color="#f2c233" loading={isLoading} size={15} />
            </div>
          </>
        )}

        <div className={styles.container}>
          {copyFromAnotherCampaign && (
            <TextCombo
              label="Campaña"
              props={{
                control,
                name: "copyFromCampaign",
                rules: {
                  required: true,
                },
              }}
            >
              <option value={""}>Seleccione una campaña</option>
              {filteredCampaigns.map((campaign: any) => (
                <option key={campaign.key} value={campaign.key}>
                  {campaign.title}
                </option>
              ))}
            </TextCombo>
          )}
          {!copyFromAnotherCampaign && !credentialsConfigured && (
            <>
              <div className={styles.container}>
                <TextField
                  label="Client Id"
                  props={{
                    name: "clientId",
                    control,
                    rules: {
                      required: true,
                    },
                  }}
                />
                <TextField
                  label="Access Token"
                  props={{
                    name: "accessToken",
                    control,
                    rules: {
                      required: true,
                    },
                  }}
                />
                <TextField
                  label="Client Secret"
                  props={{
                    name: "clientSecret",
                    control,
                    rules: {
                      required: true,
                    },
                  }}
                />
              </div>
            </>
          )}
          {!!mercadoPagoOauthUrl && !!redirectUrl && (
            <>
              <div className={styles.flex}>
                <TextField
                  label="Mercado Pago Auth"
                  props={{
                    name: "mercadoPagoOauthUrl",
                    control,
                    disabled: true,
                  }}
                  tooltipMessage="Debes pasar esta url al cliente para que reciba su parte de la recaudación de la campaña. Debe pegarla en el navegador e ingresar con su cuenta de mercado pago."
                />
                <Button
                  onClick={() => copyTextToClipboard(mercadoPagoOauthUrl)}
                  styleOverride={{ width: "min-content", padding: "10px" }}
                >
                  <Copy />
                </Button>
                <Button
                  onClick={() => openUrlInTab(mercadoPagoOauthUrl)}
                  styleOverride={{ width: "min-content", padding: "10px" }}
                >
                  <Open />
                </Button>
              </div>
              <div className={styles.flex}>
                <TextField
                  label="Url Redirección"
                  props={{
                    name: "redirectUrl",
                    control,
                    disabled: true,
                  }}
                  tooltipMessage="Copia esta url y pégala en el campo 'URLs de redireccionamiento' en 'Configuraciones avanzadas' dentro de la edición de la aplicación en mercado pago."
                />
                <Button
                  onClick={() => copyTextToClipboard(redirectUrl)}
                  styleOverride={{ width: "min-content", padding: "10px" }}
                >
                  <Copy />
                </Button>
                <Button
                  onClick={() => openUrlInTab(mercadoPagoOauthUrl)}
                  styleOverride={{ width: "min-content", padding: "10px" }}
                >
                  <Open />
                </Button>
              </div>
            </>
          )}
        </div>
      </form>
    </FieldsetLayout>
  );
}
