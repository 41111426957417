import Divider from "../Divider/Divider";
import styles from "./TitleContainer.module.css";
import Button from "../Button/Button";
import { useNavigate } from "react-router-dom";

interface Props {
  title: string;
  children: any;
  fontSize?: string;
  backURL?: string;
}

export default function TitleContainer({ title, children, fontSize = "24px", backURL }: Props) {

  let navigate = useNavigate();
  const handleBack = () => {
    if (backURL) navigate(backURL)
  }

  return (
    <div className={styles.container}>
      <div className={styles.headerContainer} style={backURL ? {marginBottom: "10px"} : {}}>
        <div className={styles.title} style={{ "fontSize": fontSize }}>{title.toUpperCase()}</div>
        {backURL && (
          <Button
            text={"Volver"}
            onClick={handleBack}
            styleOverride={{ width: "fit-content", borderRadius: "5px", height: "30px"}}
          />
        )}
      </div>
      <Divider></Divider>
      <div className={styles.data}>{children}</div>
    </div>
  );
}
