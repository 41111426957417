import Select from "../../shared/Select/Select";
import { ProductRewardsResponse } from "./product-reward.interface";

type Props = {
  activeRewards: ProductRewardsResponse[];
  setRewards: React.Dispatch<React.SetStateAction<ProductRewardsResponse[]>>;
  onRewardSelected: (reward: ProductRewardsResponse) => void;
};

export default function AddRewardSelector({ activeRewards, setRewards, onRewardSelected }: Props) {
  function onChange(event: React.ChangeEvent<HTMLSelectElement>) {
    const id = Number(event.target.value);
    const reward = findReward(id);
    if (reward) {
      onRewardSelected(reward);
      setRewards(activeRewards.filter((rewards) => rewards !== reward));
    }
  }

  const findReward = (id: number) => {
    return activeRewards.find((reward) => reward.id === id);
  };

  return (
    <Select label="Recompensas" props={{ onChange }} required>
      <option value={""}>Seleccione para agregar</option>
      {activeRewards.length &&
        activeRewards.map((reward: ProductRewardsResponse) => (
          <option key={reward.id} value={reward.id}>
            {reward.title}
          </option>
        ))}
    </Select>
  );
}
