import axios, { AxiosError } from "axios";

export const handleUnahutorizedException  = (err:Error|AxiosError, navigate: any) =>  {
  if (axios.isAxiosError(err)){
    if(err.response?.status === 401){
      navigate('/');
      return;
    }
    return err.response;
  }
}

