import axios from "axios";

const core_url = process.env.REACT_APP_CORE_URL;

const axiosAuth = axios.create({
  baseURL: core_url,
});

axiosAuth.interceptors.request.use(
  async (config) => {
    const token = localStorage.getItem("token");

    if (token) {
      config.headers = {
        ...config.headers,
        Authorization: `Bearer ${JSON.parse(token)}`,
      };
    }
    return config;
  },
  (error) => Promise.reject(error)
);

axiosAuth.interceptors.response.use(
  (response) => response,
  async (error) => {
    const token = localStorage.getItem("refreshToken");
    const config = error?.config;

    if (error?.response?.status === 401) {
      if (!token) {
        clearLocalStorage();
        return Promise.reject(error);
      }

      if (!config?.sent) {
        config.sent = true;

        const accessToken = await refreshToken(JSON.parse(token));
        if (accessToken) {
          config.headers = {
            ...config.headers,
            Authorization: `Bearer ${accessToken}`,
          };
          return axios(config);
        } else {
          clearLocalStorage();
        }
      }
    }
    return Promise.reject(error);
  }
);

const refreshToken = async (token: string) => {
  try {
    const response = await axios.post(
      `${core_url}/auth/refresh-token`,
      {
        token: token,
      },
      {
        headers: { authorization: `Bearer ${token}` },
      }
    );

    if (!response.data.accessToken) {
      clearLocalStorage();
    } else {
      localStorage.setItem("token", JSON.stringify(response.data.accessToken));
      localStorage.setItem("refreshToken", JSON.stringify(response.data.refreshToken));
    }
    return response.data.accessToken;
  } catch (error: any) {
    clearLocalStorage();
  }
  return null;
};

function clearLocalStorage() {
  localStorage.removeItem("token");
  localStorage.removeItem("refreshToken");
  localStorage.removeItem("rol");
  localStorage.removeItem("user");

  window.location.href = "/";
}
export { axiosAuth };
