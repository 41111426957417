import { createContext, useState } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Loading from "./components/Loading/Loading";
import {
  ModalMessage,
  ModalTypes,
} from "./components/ModalMessage/ModalMessage";
import Dashboard from "./pages/Dashboard/Dashboard";
import ChangePassword from "./pages/Login/ChangePassword";
import Login from "./pages/Login/Login";
import RecoverPassword from "./pages/Login/RecoverPassword";
import Lottery from "./pages/Lottery/Lottery";
import Report from "./pages/Report/Report";
import { getToken as token } from "./utils/storageUtils";
import Users from "./pages/Users/Users";
import NewUser from "./pages/Users/NewUser";
import Clients from "./pages/Clients/Clients";
import NewClient from "./pages/Clients/NewClient";
import Campaigns from "./pages/Campaign/Campaigns";
import EditCampaign from "./pages/Campaign/EditCampaign";
import Product from "./pages/Campaign/Product";
import EditClient from "./pages/Clients/EditClient";
import EditUser from "./pages/Users/EditUser";
import VoteSurveys from "./pages/Vote/VoteSurveys";
import NewVoteSurvey from "./pages/Vote/newVoteSurvey";
import Reward from "./pages/Campaign/Reward";
import TemplateDB from "./pages/TemplateDB/TemplateDB";
import ProductRewards from "./pages/ProductReward/ProductRewards";
import LayoutUser from "./components/Layout/LayoutUser";
import Layout from "./components/Layout/Layout";
import { useLocalStorage } from "./hooks/useLocalStorage";
import EditProductReward from "./pages/ProductReward/EditProductReward";
import NewProductReward from "./pages/ProductReward/NewProductReward";

let Context = createContext<ContextType | null>(null);
export type TRoleType = "adminCKO" | "adminClient" | "reader";
export type TUserData = {
  email: string;
  role: TRoleType;
};
type ContextType = {
  showWarningModal: (title: string, message: string) => void;
  showErrorModal: (title: string, message: string) => void;
  showAcceptModal: (
    title: string,
    message: string,
    onAccept: () => void
  ) => void;
  showConfirmModal: (
    title: string,
    message: string,
    onAccept: () => void
  ) => void;
  setShowLoading: (status: boolean) => void;
  getToken: any;
  getUserData: () => TUserData | undefined;
  clearUser: any;
  loading: boolean;
};

export default function App() {
  const [modal, setModal] = useState({
    show: false,
    title: "",
    message: "",
    type: ModalTypes.ERROR,
    onAccept: () => hideModal(),
  });
  const [loading, setLoading] = useState(false);
  const { getItem } = useLocalStorage()
  const hideModal = () => {
    setModal({
      show: false,
      title: "",
      message: "",
      type: ModalTypes.ERROR,
      onAccept: () => { },
    });
  };

  const getToken = token;
  const getUserData = (): TUserData => ({
    email: getItem('email') as string,
    role: getItem('role') as TRoleType,
  });

  const clearUser = () => {
    window.localStorage.clear();
  };

  const showWarningModal = (title: string, message: string) => {
    setModal({
      show: true,
      title: title,
      message: message,
      type: ModalTypes.ERROR,
      onAccept: () => { },
    });
  };

  const showErrorModal = (title: string, message: string) => {
    setModal({
      show: true,
      title: title,
      message: message,
      type: ModalTypes.WARNING,
      onAccept: () => { },
    });
  };

  const showAcceptModal = (
    title: string,
    message: string,
    onAccept: () => void
  ) => {
    setModal({
      show: true,
      title: title,
      message: message,
      type: ModalTypes.ACCEPT,
      onAccept: onAccept,
    });
  };

  const showConfirmModal = (
    title: string,
    message: string,
    onAccept: () => void
  ) => {
    setModal({
      show: true,
      title: title,
      message: message,
      type: ModalTypes.CONFIRM,
      onAccept: onAccept,
    });
  };

  const setShowLoading = (status: boolean) => {
    setLoading(status);
  };

  const appContext: ContextType = {
    showWarningModal,
    showErrorModal,
    showAcceptModal,
    showConfirmModal,
    loading,
    setShowLoading,
    getUserData,
    getToken,
    clearUser,
  };

  return (
    <>
      {!modal.show || (
        <ModalMessage
          show={modal.show}
          title={modal.title}
          message={modal.message}
          type={modal.type}
          onAccept={() => {
            hideModal();
            modal.onAccept();
          }}
          onCancel={hideModal}
        ></ModalMessage>
      )}
      {!loading || <Loading show={true} />}
      <Context.Provider value={appContext}>
        <Router>
          <Routes>
            <Route element={<Layout />}>
              <Route path="/" element={<Login />} />
              <Route path="/recover" element={<RecoverPassword />} />
              <Route path="/change-password/:hash" element={<ChangePassword />} />
            </Route>
            <Route element={<LayoutUser />}>
              <Route path="/dashboard" element={<Dashboard />} />
              <Route path="/lottery" element={<Lottery />} />
              <Route path="/report" element={<Report />} />
              <Route path="/rewards" element={<ProductRewards />} />
              <Route path="/rewards/edit" element={<EditProductReward />} />
              <Route path="/rewards/create" element={<NewProductReward />} />
              <Route path="/campaigns" element={<Campaigns />} />
              <Route path="/campaigns/:key" element={<EditCampaign />} />
              <Route path="/campaigns/:key/product/:uuid" element={<Product />} />
              <Route path="/users" element={<Users />} />
              <Route path="/users/create" element={<NewUser />} />
              <Route path="/users/edit" element={<EditUser />} />
              <Route path="/clients/edit" element={<EditClient />} />
              <Route path="/clients" element={<Clients />} />
              <Route path="/clients/create" element={<NewClient />} />
              <Route path="/clients/edit" element={<EditClient />} />
              <Route path="/vote" element={<VoteSurveys />} />
              <Route path="/vote/edit" element={<NewVoteSurvey />} />
              <Route path="/campaigns/reward" element={<Reward />} />
              <Route path="/template-db" element={<TemplateDB />} />
            </Route>
          </Routes>
        </Router>
      </Context.Provider>
    </>
  );
}

export { Context, App };
export type { ContextType };
