import axios from "axios";
import { TLoginResponse } from "../Types/login.types";

const core_url = process.env.REACT_APP_CORE_URL;

interface LoginResponseInterface {
  response: boolean;
  token?: string;
  refreshToken?: string;
  role?: string;
  clientsKeys?: Array<{
    description: string,
    isClub: boolean,
    key: string
  }>;
}

const LoginService = async (
  email: string,
  password: string
): Promise<TLoginResponse> => {
  const resp = await axios
    .post(`${core_url}/auth/admin-login`, {
      email: email,
      password: password,
    })
    .then((response) => {
      return response;
    })
    .catch((err) => {
      console.error(err);
      return err;
    });

  if (resp.status === 200 && resp.data.hasOwnProperty("accessToken")) {
    return {
      response: true,
      token: resp.data.accessToken,
      refreshToken: resp.data.refreshToken,
      role: resp.data.role,
      clientsKeys: resp.data.clients,
    };
  }
  return { response: false };
};

const RecoverPasswordService = async (email: string) => {
  const url = `${process.env.REACT_APP_PUBLIC_URL}/change-password`;
  await axios
    .post(`${core_url}/auth/recover-password-admin`, {
      email: email,
      url: url,
    })
    .then((response) => {
      return response;
    })
    .catch((err) => {
      console.error(err);
      return err;
    });
  return { response: true };
};

const ChangePasswordService = async (password: string, hash: any) => {
  return axios
    .post(`${core_url}/auth/change-password-admin`, { password, hash })
    .then(() => true);
};

export { LoginService, RecoverPasswordService, ChangePasswordService };
