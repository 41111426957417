import { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Context } from "../../App";
import Button from "../../components/Button/Button";
import ClientSelector from "../../components/ClientSelector/ClientSelector";
import Spacer from "../../components/Spacer/Spacer";
import styles from "../../components/Table/Table.module.css";
import TitleContainer from "../../components/TitleContainer/TitleContainer";
import OpenInBrowser from "../../images/OpenInBrowser";
import { ReactComponent as Edit } from "../../images/edit.svg";
import { createDefaultCampaign } from "../../services/CampaignServices";
import { getCampaigns } from "../../services/DashboardServices";
import { CampaignResponse } from "./campaign.types";

function Campaigns() {
  const [campaigns, setCampaigns] = useState<CampaignResponse[]>([]);
  const [filteredCampaigns, setFilteredCampaigns] = useState<
    CampaignResponse[]
  >([]);
  const [client, setClient] = useState({
    key: "",
    title: "",
    isClub: false,
  });

  let navigate = useNavigate();
  let context = useContext(Context);
  const { setShowLoading } = context!;

  useEffect(() => {
    setShowLoading(true);

    getCampaigns()
      .then((data) => {
        if (data) {
          setCampaigns(data);
          if (context?.getUserData()?.role === "adminClient")
            setClient({ ...client, key: data[0].client.key });
        }
      })
      .catch((error) => console.error(error))
      .finally(() => setShowLoading(false));
  }, []);

  const handleNewCampaign = async () => {
    setShowLoading(true);
    let defaultCampaign;
    if (!client.isClub) {
      defaultCampaign = await createDefaultCampaign(client.key);
    }
    navigateToCampaign(defaultCampaign?.key || "new");
    setShowLoading(false);
  };

  const navigateToCampaign = (key: string) => {
    navigate(`/campaigns/${key}`, {
      state: {
        client,
      },
    });
  };

  const openCampaignInNewTab = (clientKey: string, campaignKey: string) => {
    window.open(
      `${process.env.REACT_APP_FRONT_CLIENT_URL}/${clientKey}/collaborate/${campaignKey}`,
      "_blank",
      "noreferrer"
    );
  };

  useEffect(() => {
    const filteredCampaigns = client.key
      ? campaigns.filter((campaign: any) => campaign.client.key === client.key)
      : campaigns;
    setFilteredCampaigns(filteredCampaigns);
  }, [client, campaigns]);

  return (
    <>
      <TitleContainer title={"Campañas"}>
        <div style={{display: "flex"}}>
          <ClientSelector setClient={setClient}></ClientSelector>
          <Button
            styleOverride={{ marginLeft: "auto" }}
            text="Nueva campaña"
            disabled={!client.key}
            onClick={handleNewCampaign}
          ></Button>
        </div>
        <Spacer space="50px" />
        <table className={styles.container}>
          <thead>
            <tr>
              <th>Key</th>
              <th>Nombre</th>
              <th style={{ textAlign: "center" }}>Editar</th>
              <th style={{ textAlign: "center" }}>Ir</th>
            </tr>
          </thead>
          <tbody>
            {filteredCampaigns.map(
              (campaign: CampaignResponse, index: number) => (
                <tr key={index}>
                  <td>{campaign?.key}</td>
                  <td className={styles.w100}>{campaign?.title}</td>
                  <td
                    onClick={() => navigateToCampaign(campaign?.key)}
                    style={{ cursor: "pointer", textAlign: "center" }}
                  >
                    <Edit />
                  </td>
                  <td
                    onClick={() => {
                      if (campaign?.active && campaign.client.isClub) {
                        openCampaignInNewTab(
                          campaign.client.key,
                          campaign?.key
                        );
                      } else if (campaign?.active && campaign?.landingUrl) {
                        window.open(campaign.landingUrl);
                      }
                    }}
                    style={{
                      cursor: `${
                        campaign?.active === true ? "pointer" : "not-allowed"
                      }`,
                      textAlign: "center",
                    }}
                  >
                    <OpenInBrowser
                      currentColor={
                        campaign?.active === true ? "#000000" : "#C8C8C8"
                      }
                    />
                  </td>
                </tr>
              )
            )}
          </tbody>
        </table>
      </TitleContainer>
    </>
  );
}

export default Campaigns;
