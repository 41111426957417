import React from "react";

export default class ErrorBoundary extends React.Component<any, any> {
    state = { hasError: false };
    
    static getDerivedStateFromError(error:any) {
        return { hasError: true };
    }

    componentDidCatch(error:any, errorInfo:any) {
        console.error({ error, errorInfo });
    }

    render() {
        if (this.state.hasError) {
            return <h1>Oops, try again =/</h1>;
        }
        return this.props.children;
    }  
}