import { useEffect, useState } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import { SyncLoader } from "react-spinners";
import Switch from "react-switch";
import { usePut } from "../../hooks/usePut";
import { nonSpecialCharactersValidation } from "../../utils/formValidations.utils";
import Button from "../Button/Button";
import FieldsetLayout from "../FieldsetLayout/FieldsetLayout";
import { ModalMessage } from "../ModalMessage/ModalMessage";
import TextField from "../TextField/TextField";
import styles from "./RewardFormContainer.module.css";

type FormData = {
  isGift: boolean;
  maxClaimPerUser: number;
  title: string;
  stock: number;
  active?: boolean;
};

type Props = {
  campaignKey: string;
  reward: {
    active: boolean;
    isGift: boolean;
    maxClaimPerUser: number;
    stock: number;
    title: string;
    uuid: string;
  };
  handleRewardActive: (value: boolean) => void;
};

type Modal = "success" | "error" | "none";

const UNLIMITED_AMOUNT = -1;

export default function RewardFormContainer({
  campaignKey,
  reward,
  handleRewardActive,
}: Props) {
  const { putData, isLoading } = usePut<FormData, any>();
  const [active, setActive] = useState(reward.active);

  const [showModal, setShowModal] = useState<Modal>("none");

  const { handleSubmit, control, reset } = useForm<FormData>({
    defaultValues: {
      isGift: true,
      title: "",
    },
  });

  const handleActiveChange = (checked: boolean) => {
    setActive(checked);
    handleRewardActive(checked);
  };

  useEffect(() => {
    if (!reward) return;

    reset({
      isGift: reward.isGift,
      title: reward.title,
    });
    setActive(reward.active);
  }, [reward]);

  const onSubmit: SubmitHandler<FormData> = (data: FormData, e: any) => {
    e.preventDefault();
    const { isGift, title } = data;

    putData(`campaigns/${campaignKey}/reward/${reward.uuid}`, {
      isGift,
      maxClaimPerUser: UNLIMITED_AMOUNT,
      stock: UNLIMITED_AMOUNT,
      title,
      active,
    })
      .then(() => setShowModal("success"))
      .catch(() => setShowModal("error"));
  };

  return (
    <>
      {showModal && (
        <ModalMessage
          show={showModal !== "none"}
          title={showModal === "error" ? "Error" : "Éxito"}
          message={
            showModal === "error"
              ? "Ocurrió un problema al guardar sus cambios"
              : "Cambios guardados con éxito"
          }
          type={showModal === "error" ? "error" : "accept"}
          onAccept={() => setShowModal("none")}
        />
      )}
      <form className={styles.container} onSubmit={handleSubmit(onSubmit)}>
        <div style={{ gridColumn: "1/3" }}>
          <TextField
            label="Título"
            props={{
              name: "title",
              control,
              rules: {
                required: true,
                pattern: nonSpecialCharactersValidation,
              },
            }}
            readOnly={!active}
          />
        </div>
        <div className={styles.saveBtn}>
          {!isLoading && (
            <Button
              text={"Guardar cambios"}
              type="submit"
              styleOverride={{ width: "200px" }}
            />
          )}
          <SyncLoader color="#f2c233" loading={isLoading} size={15} />
        </div>
        <label className={styles.switchContainer}>
          <span>Recompensa Activa</span>
          <Switch onChange={handleActiveChange} checked={active} />
        </label>
      </form>
    </>
  );
}
