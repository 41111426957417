import { useState } from "react";
import Switch from "react-switch";
import { useForm, SubmitHandler } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { ClientCreateDto } from "./clients.types";
import { nonSpecialCharactersValidation } from "../../utils/formValidations.utils";
import { usePost } from "../../hooks/usePost";
import TitleContainer from "../../components/TitleContainer/TitleContainer";
import Button from "../../components/Button/Button";
import TextField from "../../components/TextField/TextField";
import stylesClient from "./../Users/Users.module.css";
import styles from "../../components/Table/Table.module.css";
import { getAllClientsForUser } from "../../services/ClientServices";

type UserFormData = {
  title: string;
  subtitle: string;
  isClub: boolean;
};

export default function NewClient() {
  const { postData, isLoading, error } = usePost<ClientCreateDto, any>();
  const navigate = useNavigate();
  const [isClub, setIsClub] = useState<boolean>(false);

  const { handleSubmit, control } = useForm<UserFormData>({
    defaultValues: {
      title: "",
      subtitle: "",
    },
  });

  const handleIsClub = (checked: boolean) => {
    setIsClub(checked);
  };

  const onSubmit: SubmitHandler<UserFormData> = async (
    data: UserFormData,
    e: any
  ) => {
    e.preventDefault();

    const { title, subtitle } = data;
    postData("clients", {
      title,
      subtitle,
      isClub,
    }).then((_response) => {
      getAllClientsForUser();
      navigate(-1);
    });
  };

  if (isLoading) {
    return <p>Loading...</p>;
  }
  if (error) {
    return <p>Error: {error}</p>;
  }
  return (
    <TitleContainer title="Cliente" backURL="/clients">
      <fieldset className={stylesClient.fieldset}>
        <legend>Nuevo Cliente</legend>
        <form
          onSubmit={handleSubmit(onSubmit)}
          style={{ display: "flex", flexDirection: "column", gap: "1rem" }}
        >
          <TextField
            label="Nombre"
            props={{
              name: "title",
              control,
              rules: {
                required: true,
                pattern: nonSpecialCharactersValidation,
              },
            }}
          />
          <TextField
            label="Descripción"
            props={{
              name: "subtitle",
              control,
              rules: {
                required: true,
                pattern: nonSpecialCharactersValidation,
              },
            }}
          />

          <label style={{ display: "flex", alignItems: "center", gap: "10px" }}>
            <span
              style={{
                color: "#f2c233",
                fontSize: "1rem",
                fontFamily: "Roboto",
                fontWeight: 600,
              }}
            >
              ¿Es club?
            </span>
            <Switch
              onChange={handleIsClub}
              checked={isClub}
              className={styles.reactSwitch}
            />
          </label>

          <Button
            text="Crear cliente"
            type="submit"
            styleOverride={{ margin: "0 auto" }}
          />
        </form>
      </fieldset>
    </TitleContainer>
  );
}
