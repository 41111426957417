import React, { ChangeEvent, useEffect, useRef, useState } from "react";
import styles from "./MailStyleContainer.module.css";
import { SubmitHandler, useForm } from "react-hook-form";
import Button from "../Button/Button";
import { SyncLoader } from "react-spinners";
import FileInput from "../FileInput/FileInput";
import { ColorPicker } from "../ColorPicker/ColorPicker";

type FormData = {
  headerFile: string;
  footerFile: string;
};

export default function BodyMailContainer({
  uploadEmailStyle,
  campaign,
  loading
}: any) {
  const [file, setFile] = useState<{
    headerFile: File | null;
    footerFile: File | null;
  }>({
    headerFile: null,
    footerFile: null,
  });
  const [fontColor, setFontColor] = useState<string>();
  const [backgroundColor, setBackgroundColor] = useState<string>();

  const previewHeaderImage = useRef<HTMLImageElement>(null);
  const previewFooterImage = useRef<HTMLImageElement>(null);

  const { handleSubmit } = useForm<FormData>();

  useEffect(() => {
    if (campaign) {
      setFile({
        headerFile: campaign.headerFile || null,
        footerFile: campaign.footerFile || null,
      });
      setFontColor(campaign.theme.data.email.fontColor);
      setBackgroundColor(campaign.theme.data.email.backgroundColor);
    }
  }, [campaign]);

  const onSubmit: SubmitHandler<FormData> = (data: FormData) => {
    if (!campaign?.key) return;
    
    try {
      uploadEmailStyle({
        key: campaign.key,
        headerFile: file.headerFile,
        footerFile: file.footerFile,
        backgroundColor: backgroundColor,
        fontColor: fontColor
      });
    } catch (err) {
      console.log(err, "error en MailStyleContainer");
    }
  };

  const handleFileChange = (e: ChangeEvent<HTMLInputElement>) => {
    const files = e.target.files;
    if (files && files.length > 0) {
      setFile((prevFile) => ({
        ...prevFile,
        headerFile: files[0],
      }));
      if (previewHeaderImage.current && files[0]) {
        previewHeaderImage.current.src = URL.createObjectURL(files[0]);
      }
    }
  };

  const handleFileChangeFooter = (e: ChangeEvent<HTMLInputElement>) => {
    const files = e.target.files;
    if (files && files.length > 0) {
      setFile((prevFile) => ({
        ...prevFile,
        footerFile: files[0],
      }));
      if (previewFooterImage.current && files[0]) {
        previewFooterImage.current.src = URL.createObjectURL(files[0]);
      }
    }
  };

  return (
    <form className={styles.formBodyMail} onSubmit={handleSubmit(onSubmit)}>
      <div className={styles.imagesMailContainer}>
        <div className={styles.saveBtn}>
          {!loading && (
            <Button
              text={"Guardar cambios"}
              type="submit"
              styleOverride={{ width: "200px" }}
            />
          )}
          <SyncLoader color="#f2c233" loading={loading} size={15} />
        </div>
        <div className={styles.bodyMailExample}>
          {file.headerFile ? (
            <img
              ref={previewHeaderImage}
              src={file.headerFile ? URL.createObjectURL(file.headerFile) : ""}
              alt="Header"
              className={styles.bodyMailHeaderExample}
            />
          ) : (
            <div className={styles.bodyMailHeaderExample}></div>
          )}
          <p
            className={styles.bodyMailCuerpoExample}
            style={{ color: fontColor }}
          >
            Este es un ejemplo de cómo se verá el email con su encabezado y su footer,
            siendo este el cuerpo del email.
          </p>

          <div
            className={styles.bodyMailFooterExample}
            style={{ backgroundColor: backgroundColor }}
          >
            {file.footerFile && (
              <img
                ref={previewFooterImage}
                src={
                  file.footerFile ? URL.createObjectURL(file.footerFile) : ""
                }
                alt="Footer"
              />
            )}
          </div>
        </div>
        <div className={styles.fileInput}>
          <div style={{ display: "flex", gap: "20px" }}>
            <ColorPicker setValue={setFontColor} label="Color de fuente" defaultColor={fontColor}/>
            <ColorPicker setValue={setBackgroundColor} label="Color de fondo" defaultColor={backgroundColor}/>
          </div>
          <FileInput
            handleFileChange={handleFileChange}
            inputName="headerFile"
            label="Subir imagen encabezado"
          />
          <FileInput
            handleFileChange={handleFileChangeFooter}
            inputName="footerFile"
            label="Subir imagen footer"
          />
        </div>
      </div>
    </form>
  );
}
