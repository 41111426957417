import styles from "./Navbar.module.css";
import logo from '../../images/logo.png';

export default function Navbar() {
  return (
    <div className={styles.container}>
      <img src={logo} alt="logo"></img>
    </div>
  );
}
