import { useState } from "react";
import { axiosAuth } from "../services/HttpService";

export function usePut<TBody, TResult>() {
  const [error, setError] = useState<string | undefined>(undefined);
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState<TResult | undefined>(undefined);
  const core_url = process.env.REACT_APP_CORE_URL;

  const putData = async (path: string, body: TBody) => {
    setIsLoading(true);

    return axiosAuth
      .put(`${core_url}/${path}`, body)
      .then((response) => {
        if (response.status !== 200) {
          setError(response.data.error);
        }
        if (response.status === 200) {
          setData(response.data);
          setError(undefined);  
        }
      })
      .catch((error) => setError(error.response?.data.message || error.message))
      .finally(() => setIsLoading(false));
  };

  return { putData, data, isLoading, error };
}
