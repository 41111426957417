import { HTMLAttributes } from "react";
import styles from "./Select.module.css";

type Props = {
  label: string;
  children: React.ReactNode;
  color?: string;
  required?: boolean;
  props: HTMLAttributes<HTMLSelectElement>;
};

function Select({ label, children, color, required, props }: Props) {
  return (
    <div className={styles.container}>
      <span
        style={{
          color: color,
        }}
        className={styles.label}
      >
        <div>{label}</div>
        {required && <span className={styles.required}>*</span>}
      </span>
      <select
        className={styles.select}
        {...props}
      >
        {children}
      </select>
    </div>
  );
}

export default Select;
