import { Outlet } from "react-router-dom";
import NavbarUser from "../Navbar/NavbarUser";
import Spacer from "../Spacer/Spacer";

export default function LayoutUser() {
  return <>
    <NavbarUser />
    <Spacer space="50px" />
    <Outlet />
  </>;
}
