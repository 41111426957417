import Spacer from "../../components/Spacer/Spacer";
import TitleContainer from "../../components/TitleContainer/TitleContainer";
import Button from "../../components/Button/Button";
import TextField from "../../components/TextField/TextField";
import { useForm, SubmitHandler } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import Switch from "react-switch";
import { useState } from "react";
import { useLocation } from "react-router-dom";
import { usePut } from "../../hooks/usePut";
import styles from "../../components/Table/Table.module.css";
import stylesClient from "./../Users/Users.module.css";
import { nonSpecialCharactersValidation } from "../../utils/formValidations.utils";
import { UserEditDto, UsersResponse } from "./users.types";
import TextCombo from "../../components/TextCombo/TextCombo";

type UserFormData = {
  name: string;
  lastName: string;
  email: string;
  status: boolean;
  role: string;
};

interface LocationState {
  user: UsersResponse;
}

export default function EditUser() {
  const location = useLocation();
  const { user } = location.state as LocationState;
  const { name, lastName, active, email, role } = user;
  const { putData, isLoading, error } = usePut<UserEditDto, any>();
  const navigate = useNavigate();

  const [status, setStatus] = useState<boolean>(active);
  const handleChange = (checked: boolean) => {
    setStatus(checked);
  };

  const { handleSubmit, control } = useForm<UserFormData>({
    defaultValues: {
      name,
      lastName,
      email,
      status,
      role: role.key,
    },
  });

  const onSubmit: SubmitHandler<UserFormData> = async (
    data: UserFormData,
    e: any
  ) => {
    e.preventDefault();

    const { name, lastName, role } = data;

    console.log("role--->", role);
    putData(`users`, {
      email,
      name,
      lastName,
      status,
      role
    }).then((_response) => {
      navigate(-1);
    });
  };

  if (isLoading) {
    return <p>Loading...</p>;
  }
  if (error) {
    return <p>Error: {error}</p>;
  }
  return (
    <TitleContainer title="Usuario" backURL="/users">
      <fieldset className={stylesClient.fieldset}>
        <legend>Editar Usuario</legend>
        <form onSubmit={handleSubmit(onSubmit)}>
          <TextField
            label="Email"
            props={{
              name: "email",
              control,
              disabled: true,
            }}
          />
          <Spacer space="20px" />
          <TextField
            label="Nombre"
            props={{
              name: "name",
              control,
              rules: {
                required: true,
                pattern: nonSpecialCharactersValidation,
              },
            }}
          />
          <Spacer space="20px" />
          <TextField
            label="Apellido"
            props={{
              name: "lastName",
              control,
              rules: {
                required: true,
                pattern: nonSpecialCharactersValidation,
              },
            }}
          />
          <TextCombo
            label="Rol"
            props={{
              control,
              name: "role",
            }}
          >
            <option key={1} value="reader">Lector</option>
            <option key={2} value="adminClient">
              Cliente administrador
            </option>              
            <option key={3} value="adminCKO">
              Administrador CKO
            </option>
          </TextCombo>

          <Spacer space="20px" />
          <label
            style={{ display: "flex", alignItems: "center", gap: "10px" }}
          >
            <span
              style={{
                color: "#f2c233",
                fontSize: "1rem",
                fontFamily: "Roboto",
                fontWeight: 600,
              }}
            >
              Activo
            </span>
            <Switch
              onChange={handleChange}
              checked={status}
              className={styles.reactSwitch}
            />
          </label>

          <Spacer space="20px" />
          <Button
            text="Editar"
            type="submit"
            styleOverride={{ margin: "0 auto" }}
          />
        </form>
      </fieldset>
    </TitleContainer>
  );
}
