import { useController } from "react-hook-form";
import styles from "./TextCombo.module.css";

function TextCombo({ label, children, color, props, disabled, onChange }: any) {
  const { field } = useController(props);

  return (
    <div className={styles.container}>
      <span
        style={{
          color: color,
        }}
        className={styles.label}
      >
        <div>
          {label}
          {props.rules?.required && (
            <span style={{ color: "red", fontSize: "0.875rem" }}>{" *"}</span>
          )}
        </div>
      </span>
      <select className={styles.select}
        {...field}
        disabled={disabled}
        onChange={(e) => {
          field.onChange(e);
          if (props.onChange) {
            props.onChange(e);
          }
        }}
      >
        {children}
      </select>
    </div>
  );
}

export default TextCombo;
