import { useEffect, useState } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { SyncLoader } from "react-spinners";
import stylesTable from "../../components/Table/Table.module.css";
import { useGet } from "../../hooks/useGet";
import { usePut } from "../../hooks/usePut";
import { ReactComponent as Edit } from "../../images/edit.svg";
import { CampaignDetailedResponse } from "../../pages/Campaign/campaign.types";
import { nonSpecialCharactersValidation } from "../../utils/formValidations.utils";
import Button from "../Button/Button";
import { ColorPicker } from "../ColorPicker/ColorPicker";
import FieldsetLayout from "../FieldsetLayout/FieldsetLayout";
import { ModalMessage, ModalTypes } from "../ModalMessage/ModalMessage";
import TextCombo from "../TextCombo/TextCombo";
import TextField from "../TextField/TextField";
import styles from "./RewardsContainer.module.css";

interface Props {
  campaign: CampaignDetailedResponse;
  campaignKey: string;
}

type FormData = {
  color: string;
  size: string;
  weight: any;
  family: string;
};

export default function RewardsContainer({ campaign, campaignKey }: Props) {
  const { getData, data } = useGet<any>();
  const { putData, isLoading, error } = usePut<FormData, any>();
  let navigate = useNavigate();
  const [showModal, setShowModal] = useState<boolean>(false);
  const [isClub, setIsClub] = useState(false);

  const { handleSubmit, control, watch, getValues, reset } = useForm<FormData>({
    defaultValues: {
      size: "",
      weight: 0,
      family: "",
    },
  });

  const [fontColor, setFontColor] = useState<string>();

  useEffect(() => {
    getData(`campaigns/${campaignKey}/reward-templates`);
    setIsClub(campaign.client.isClub);
  }, []);

  useEffect(() => {
    if (!campaign.theme.data.reward) return;

    const reward = campaign.theme.data.reward.font;
    setFontColor(reward.color);
    reset({
      size: reward.size,
      weight: reward.weight,
      family: reward.family,
      color: reward.color,
    });
  }, [campaign]);

  const onSubmit: SubmitHandler<FormData> = (data: FormData, e: any) => {
    e.preventDefault();
    const { size, weight, family } = data;

    putData(`campaigns/${campaignKey}/reward-style`, {
      size,
      weight: parseInt(weight),
      family,
      color: fontColor || "",
    }).finally(() => setShowModal(true));
  };

  useEffect(() => {
    const fontName = getValues("family");
    if (!fontName) return;
    const link = document.createElement("link");
    link.rel = "stylesheet";
    link.href = `https://fonts.googleapis.com/css?family=${fontName}`;
    document.head.appendChild(link);
  }, [watch("family")]);

  const navigateToReward = (rewardTemplate: any) => {
    navigate("/campaigns/reward", {
      state: { rewardTemplate, campaignKey: campaignKey },
    });
  };

  return (
    <>
      {!data?.length && (
        <div className={styles.addProductButton}>
          <Button
            text="Agregar nueva plantilla"
            onClick={() => navigateToReward(null)}
          />
        </div>
      )}

      <div className={styles.container}>
        {isClub && (
          <div style={{ width: "600px" }}>
            <FieldsetLayout label="Personalización de Plantillas">
              <>
                {showModal && (
                  <ModalMessage
                    show={true}
                    title={error ? "Error" : "Cambios guardados correctamente"}
                    message={
                      error
                        ? error
                        : "Los cambios se han guardado correctamente"
                    }
                    type={error ? ModalTypes.ERROR : ModalTypes.ACCEPT}
                    onAccept={() => setShowModal(false)}
                    onCancel={() => setShowModal(false)}
                  ></ModalMessage>
                )}
                <form
                  onSubmit={handleSubmit(onSubmit)}
                  className={styles.formContainer}
                >
                  {isLoading ? (
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <SyncLoader
                        color="#f2c233"
                        loading={isLoading}
                        size={15}
                      />
                    </div>
                  ) : (
                    <Button
                      text={"Guardar cambios"}
                      type="submit"
                      styleOverride={{ width: "200px", marginLeft: "auto" }}
                    />
                  )}
                  <TextField
                    label="Fuente"
                    props={{
                      name: "family",
                      control,
                      rules: {
                        required: true,
                        pattern: nonSpecialCharactersValidation,
                      },
                    }}
                  />
                  <TextCombo
                    label="Peso de Fuente"
                    props={{
                      name: "weight",
                      control,
                      rules: {
                        required: true,
                      },
                    }}
                  >
                    <option value={"100"}>100</option>
                    <option value={"200"}>200</option>
                    <option value={"300"}>300</option>
                    <option value={"400"}>400</option>
                    <option value={"500"}>500</option>
                    <option value={"600"}>600</option>
                    <option value={"700"}>700</option>
                    <option value={"800"}>800</option>
                    <option value={"900"}>900</option>
                  </TextCombo>
                  <TextField
                    label="Tamaño de fuente"
                    props={{
                      name: "size",
                      control,
                      rules: {
                        required: true,
                        pattern: nonSpecialCharactersValidation,
                      },
                    }}
                  />
                  <ColorPicker
                    setValue={setFontColor}
                    label="Color de Fuente"
                    defaultColor={fontColor}
                  />
                  <div
                    className={styles.fontPreview}
                    style={{
                      fontWeight: watch("weight"),
                      fontSize: watch("size"),
                      color: fontColor,
                      fontFamily: watch("family"),
                    }}
                  >
                    Preview de Fuente
                  </div>
                </form>
              </>
            </FieldsetLayout>
          </div>
        )}
        {data?.length > 0 ? (
          <div
            className={stylesTable.overflowContainer}
            style={{ width: "100%" }}
          >
            <table className={stylesTable.container}>
              <thead>
                <tr>
                  <th
                    colSpan={8}
                    style={{
                      textAlign: "center",
                      borderBottom: "1px solid white",
                    }}
                  >
                    Plantillas
                  </th>
                </tr>
                <tr>
                  <th>Imagen</th>
                  <th>Nombre</th>
                  <th style={{ textAlign: "center" }}>Editar</th>
                </tr>
              </thead>
              <tbody>
                {data.map((reward: any, i: number) => (
                  <tr key={i}>
                    <td>
                      {reward?.url && (
                        <img
                          src={reward.url}
                          width="80px"
                          alt={`Imagen de ${reward.title}`}
                        />
                      )}
                    </td>
                    <td style={{ width: "100%" }}>{reward.title}</td>
                    <td
                      onClick={() => navigateToReward(reward)}
                      style={{ textAlign: "center" }}
                    >
                      <Edit />
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        ) : (
          <h2 className={styles.noProducts}>
            Aún no se han agregado plantillas
          </h2>
        )}
      </div>
    </>
  );
}
