import { formatCurrency } from "../../utils/format.utils"
import styles from "./TopContributors.module.css"


export default function TopContributors({ topContributors = [] }: any) {

    return (
        <table className={styles.tableTopContributors} id="contributorsTable">
            <thead>
                <tr>
                    <th className={styles.tableHeaderEmail}>
                        <p className={styles.tableHeaderEmailText}>Email</p>
                        <p className={styles.tableHeaderEmailTextMobile}>Contribuyentes</p>
                    </th>
                    <th className={styles.tableHeaderAportes}>Aportes</th>
                    <th className={styles.tableHeaderTotal}>Total</th>
                </tr>
            </thead>
            <tbody>
                {topContributors.slice(0, 10).map((contributor: any, i: string) => (
                    <tr key={i}>
                        <td className={styles.tableDataEmail}>
                            <p>{contributor.email}</p>
                            <p className={styles.tableDataEmailAportes}>Aportes: {contributor.contributions}</p>
                            <p className={styles.tableDataEmailTotal}>Total: {formatCurrency(contributor.total, 'en')}</p>
                        </td>
                        <td className={styles.tableDataAportes}>{contributor.contributions}</td>
                        <td className={styles.tableDataTotal}>{formatCurrency(contributor.total, 'en')}</td>
                    </tr>
                ))}
            </tbody>
        </table>
    )
}
