import axios from "axios";
import { jsPDF } from "jspdf";
import {
  CampaignInterface,
  GetLotteryResponse,
  Winner,
} from "../Interfaces/campaign";
import { Country } from "../services/ReportServices";
import { formatCurrency, formatDateStringDMY } from "../utils/format.utils";
import { takeScreenshot } from "../utils/utils";
import { axiosAuth } from "./HttpService";
import { CampaignResponse } from "../pages/Campaign/campaign.types";

const core_url = process.env.REACT_APP_CORE_URL;
interface PostLotteryResponse {
  response: boolean;
  winners?: Winner[];
}

export interface GetProgressResponse {
  response: boolean;
  totalAmount?: string;
  percentage?: number;
  objective?: string;
}

const getCampaigns = async (): Promise<CampaignResponse[]> => {
  const resp = await axiosAuth.get(`/users/campaigns`).then((response) => {
    return response;
  });
  if (resp.status === 200) {
    return resp.data;
  }

  return [];
};

const getReportRaised = async (campaignKey: string) => {
  return axiosAuth
    .get(`/reports?key=raised&campaign=${campaignKey}`)
    .then((response) => response.data);
};

const getLottery = async (
  campaign: CampaignInterface
): Promise<GetLotteryResponse> => {
  const resp = await axiosAuth
    .get(`/campaigns/${campaign.key}/lottery`)
    .then((response) => {
      return response;
    });
  if (resp.status === 200) {
    return { response: true, lottery: resp.data };
  }
  return { response: false };
};

const postLottery = async (
  campaign: CampaignInterface
): Promise<PostLotteryResponse> => {
  const resp = await axiosAuth
    .post(`/campaigns/${campaign.key}/lottery`, null)
    .then((response) => {
      return response;
    });
  if (resp.status === 201) {
    return { response: true, winners: resp.data.results };
  }
  return { response: false };
};

const getProgressData = async (
  campaign: CampaignInterface
): Promise<GetProgressResponse> => {
  const resp = await axios
    .get(`${core_url}/campaigns/${campaign.key}/progress`)
    .then((response) => {
      return response;
    })
    .catch((err) => {
      console.error(err);
      return err;
    });

  if (resp.status === 200) {
    return {
      response: true,
      percentage: resp.data.percentage,
      totalAmount: resp.data.totalAmount,
      objective: resp.data.objective,
    };
  }
  return { response: false };
};

const addImage = async (
  ref: React.MutableRefObject<any>,
  elementId: string,
  doc: any,
  marginLeft: number,
  yPosition: number,
  width: number,
  height: number,
  message: string = "No hay datos suficientes para generar un gráfico aún"
) => {
  const element = ref.current.querySelector(elementId);
  let pageWidth = doc.internal.pageSize.getWidth();
  if (element) {
    const img = await takeScreenshot(element);
    if (img)
      doc.addImage(
        img,
        "JPG",
        marginLeft,
        yPosition,
        width,
        height,
        "",
        "NONE",
        0
      );
  } else {
    doc.setFontSize(12);
    doc.text(message, pageWidth / 2, yPosition + height / 2, {
      align: "center",
    });
  }
};

export async function createDashboardReport(
  ref: React.MutableRefObject<any>,
  campaign: any,
  percentage = 0,
  totalRaised = '',
  objectiveNumber = "0",
  countriesData: Country[],
  convertionRateData: any
) {
  const doc = new jsPDF({
    orientation: "p",
    unit: "px",
    format: "a4",
    hotfixes: ["px_scaling"],
  });

  const marginLeft = 80;
  const lineSpacing = 23;

  //21x29.7
  let img = new Image();
  img.src = require("../images/escudo.png");

  doc.setFontSize(14);
  doc.addImage(img, "PNG", 650, 50, marginLeft, 100);
  let pageWidth = doc.internal.pageSize.getWidth();

  doc.text("REPORTE DE CAMPAÑA", pageWidth / 2, 180, { align: "center" });
  doc.text(`${campaign.title}`, pageWidth / 2, 220, { align: "center" });

  doc.setFontSize(12);
  const date = new Date();
  const dateString = formatDateStringDMY(date.toISOString(), "/");
  doc.text(
    `Fecha y hora: ${dateString} - ${date.getHours()}:${
      date.getMinutes() < 10 ? "0" + date.getMinutes() : date.getMinutes()
    }`,
    marginLeft,
    300,
    { align: "left" }
  );

  doc.text(`Cliente: ${campaign.client.title}`, marginLeft, 340);

  doc.text(
    `Estado de campaña: ${campaign.active ? "Activa" : "Inactiva"}`,
    marginLeft,
    400
  );
  doc.text(
    `Fecha de fin de Campaña: ${
      campaign.endDate ? formatDateStringDMY(campaign.endDate, "/") : ""
    }`,
    marginLeft,
    440
  );

  doc.setFontSize(14);
  doc.text(`${campaign.client.title} en números`, marginLeft, 500);
  const textWidth = doc.getTextWidth(`${campaign.client.title} en números`);
  doc.line(marginLeft, 503, marginLeft + textWidth, 503);

  const hasTotalCampaign = ref.current.querySelector("#totalCampaign");
  let yOffset = 0;

  if (hasTotalCampaign) {
    await addImage(ref, "#totalCampaign", doc, marginLeft, 680, 200, 128);
    yOffset = 140;
  }

  await addImage(ref, "#totalCampaignCKO", doc, marginLeft, 540, 200, 128);
  await addImage(ref, "#contributions", doc, 520, 540, 200, 128);
  await addImage(ref, "#contributors", doc, 300, 540, 200, 128);

  doc.setFontSize(12);
  doc.text(
    "Cantidad de participantes: Cantidad de usuarios registrados que han realizado al menos",
    marginLeft,
    710 + yOffset
  );
  doc.text("una contribución.", marginLeft, 710 + lineSpacing + yOffset);

  doc.text(
    "Cantidad de contribuciones: Total de contribuciones (tipo aporte o compra de producto) ",
    marginLeft,
    710 + lineSpacing * 3 + yOffset
  );
  doc.text("realizadas a la campaña.", marginLeft, 710 + lineSpacing * 4 + yOffset);

  doc.setFontSize(14);
  doc.text("Objetivo de la campaña", marginLeft, 840 + yOffset);
  const goalTextWidth = doc.getTextWidth("Objetivo de la campaña");
  doc.line(marginLeft, 843 + yOffset, marginLeft + goalTextWidth, 843 + yOffset);

  await addImage(ref, "#progressBar", doc, marginLeft, 890 + yOffset, 633, 17);

  doc.setFontSize(12);
  doc.text(
    `Objetivo: ${objectiveNumber}`,
    marginLeft,
    950 + yOffset
  );
  doc.text(
    `Total Recaudado: ${totalRaised} (${percentage}%)`,
    marginLeft,
    950 + lineSpacing + yOffset
  );
  doc.setFontSize(10);
  doc.text(
    "*El porcentaje presentado incluye comisión para CKO.",
    marginLeft,
    950 + lineSpacing * 2 + yOffset
  );

  doc.addPage();

  doc.setFontSize(14);
  doc.text("Análisis gráficos", marginLeft, 100);
  const graphTextWidth = doc.getTextWidth("Análisis gráficos");
  doc.line(marginLeft, 103, marginLeft + graphTextWidth, 103);

  await addImage(ref, "#countriesGraph", doc, marginLeft + 100, 120, 170, 244);
  await addImage(ref, "#convertionGraph", doc, marginLeft + 400, 120, 170, 235);

  doc.setFontSize(12);
  doc.text("Donaciones por país: (Primeros 5 países)", marginLeft, 400);

  let yPosition = 400;

  countriesData.every((country: any, index: number) => {
    if (index === 5) return false;
    doc.text(
      `${country.country}: ${country.count}`,
      marginLeft,
      yPosition + lineSpacing
    );
    yPosition = yPosition + lineSpacing;
    return true;
  });

  yPosition += lineSpacing * 2;

  doc.text("Tasa de conversión:", marginLeft, yPosition);
  doc.text(
    `Contribuciones aprobadas: ${convertionRateData.approvedCarts || 'Sin datos'}`,
    marginLeft,
    yPosition + lineSpacing
  );
  doc.text(
    "(Cantidad de contribuciones por carritos con pago aprobado).",
    marginLeft,
    yPosition + lineSpacing * 2
  );
  doc.text(
    `Contribuciones incompletas: ${convertionRateData.invalidCarts || 'Sin datos'}`,
    marginLeft,
    yPosition + lineSpacing * 3
  );
  doc.text(
    "(Cantidad de contribuciones por carritos con pagos no realizados, cancelados o pendientes)",
    marginLeft,
    yPosition + lineSpacing * 4
  );

  yPosition += lineSpacing * 4; //max 653

  await addImage(
    ref,
    "#barGraph",
    doc,
    marginLeft + 80,
    yPosition + 80,
    500,
    243
  );

  yPosition = yPosition + 360;

  doc.text(
    "Conversiones diarias: Número de carritos con pago aprobado por día, durante los últimos",
    marginLeft,
    yPosition
  );
  doc.text("7 días.", marginLeft, yPosition + lineSpacing);

  doc.addPage();

  doc.setFontSize(14);
  doc.text("TOP 10 contribuyentes:", marginLeft, 100);
  const top10TextWidth = doc.getTextWidth("TOP 10 contribuyentes:");
  doc.line(marginLeft, 103, marginLeft + top10TextWidth, 103);
  const contributorsTable = ref.current.querySelector("#contributorsTable");

  let top10Width = contributorsTable
    ? ref.current.querySelector("#contributorsTable")?.offsetWidth / 2
    : 0;
  let top10Height = contributorsTable
    ? ref.current.querySelector("#contributorsTable")?.offsetHeight / 2
    : 0;
  await addImage(
    ref,
    "#contributorsTable",
    doc,
    marginLeft,
    120,
    top10Width + 100,
    top10Height + 100
  );

  const campignFileName = campaign.title.replace(/ /g, "-");
  doc.save(`Reporte-${campignFileName}.pdf`);
}

export {
  getCampaigns,
  getLottery,
  getProgressData,
  getReportRaised,
  postLottery,
};
