import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import { Pie } from 'react-chartjs-2';

ChartJS.register(ArcElement, Tooltip, Legend);

export interface GraphProps {
  data: { labels: string[], datasets: DataSet[] }
}

export interface DataSet {
  label: string
  data: number[]
  backgroundColor: string[]
  borderColor: string[]
  borderWidth: number
}

export const PieGraph = ({ data }: GraphProps) => {
  return (
    <div style={{ width: "100%", height: "100%", maxHeight: "400px" }}>
      <Pie
        data={data}
        redraw={true}
        options={{
          maintainAspectRatio: false,
          plugins: {
            legend: {
              position: 'bottom',
              labels: {
                boxWidth: 20,
                color: 'white'
              }
            }
          }
        }} />
    </div>
  );
};
