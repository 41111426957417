import { useEffect } from "react";
import { useGet } from "../../hooks/useGet";
import { UsersResponse } from "./users.types";

export function useGetUsers() {
  const { getData, data, isLoading, error } = useGet<UsersResponse[]>();

  useEffect(() => {
    async function fetch() {
      await getData("users/admin-users");
    }
    fetch();
  }, []);

  const users = data?.map((user) => {
    const userClients = user.clients.map((userClient) => userClient.title).join(' - ');
    return {
      ...user,
      userClients
    }
  });
  return { users, isLoading, error };
}
