import { DataSet } from "../components/Graphs/PieGraph";
import { PaymentType } from "../utils/payments";
import { axiosAuth } from "./HttpService";

export interface DonatorData {
    totalContributors: number
    totalUsers: number
}

export interface Country {
    country: string
    count: number
}

export interface CountriesData {
    countriesList: Country[]
    totalUsers: number
}

export interface PaymentsData {
    labels: string[]
    datasets: DataSet[]
}

export interface PaymentResponse {
    status: PaymentType
    count: string
}

export const getContributorsToExcel = async (campaignKey: string, fromDate: string, toDate: string) => {
    return axiosAuth.get(`/users-report/contributors-to-excel?campaign=${campaignKey}&from=${fromDate}&to=${toDate}`)
        .then((response) => {
            const link = document.createElement('a');
            link.setAttribute('href', 'data:text/csv;charset=utf-8,' + encodeURIComponent('\uFEFF' + response.data));
            const filename = response.headers["content-disposition"].split("; ")[1]
                .replace("filename=", "").replaceAll('"', '').trim();
            link.setAttribute('download', `${filename}`);
            document.body.appendChild(link);
            link.click();
        })
}

export const getContributors = async (campaignKey: string, fromDate: string, toDate: string) => {
    return axiosAuth.get(`/users-report/contributors?campaign=${campaignKey}&from=${fromDate}&to=${toDate}`)
        .then((response) => {
            return response.data
        })
}

export const getBuyers = async (campaignKey: string) => {
    return axiosAuth.get(`/users-report/buyers?campaign=${campaignKey}`)
        .then((response) => {
            const link = document.createElement('a');
            link.setAttribute('href', 'data:text/csv;charset=utf-8,' + encodeURIComponent('\uFEFF' + response.data));
            const filename = response.headers["content-disposition"].split("; ")[1]
                .replace("filename=", "").replaceAll('"', '').trim();
            link.setAttribute('download', `${filename}`);
            document.body.appendChild(link);
            link.click();
        })
}


export const getCountriesReport = async (campaignKey: string): Promise<CountriesData> => {
    const contributorsData = await axiosAuth.get(`/users-report/countries?campaign=${campaignKey}`)
    return contributorsData.data
}

export const getPayments = async (campaignKey: string): Promise<PaymentResponse[]> => {
    let paymentsData = axiosAuth.get(`/users-report/payments?campaign=${campaignKey}`)
        .then((response) => {
            return response.data
        })
    return paymentsData
}

export const getConversionRate = async (campaignKey: string) => {
    return axiosAuth
        .get(`/users-report/convertion-rate?campaign=${campaignKey}`)
        .then((response) => response.data);
};

export const getTopContributors = async (campaignKey: string) => {
    return axiosAuth
        .get(`/users-report/${campaignKey}/top-contributors`)
        .then((response) => response.data);
}

export const getDailyConvertions = async (campaignKey: string) => {
    return axiosAuth
        .get(`/reports/campaigns/${campaignKey}/convertions?days=7`)
        .then((response) => response.data);
}
