import { useEffect, useState } from "react";
import { SubmitHandler, useFieldArray, useForm } from "react-hook-form";
import { useLocation, useNavigate } from "react-router-dom";
import Switch from "react-switch";
import Button from "../../components/Button/Button";
import TextCombo from "../../components/TextCombo/TextCombo";
import TextField from "../../components/TextField/TextField";
import TitleContainer from "../../components/TitleContainer/TitleContainer";
import { usePost } from "../../hooks/usePost";
import { usePut } from "../../hooks/usePut";
import {
  isFutureDateValidation,
  nonSpecialCharactersValidation,
} from "../../utils/formValidations.utils";
import { formatDateStringYMD } from "../../utils/format.utils";
import styles from "./VoteSurvey.module.css";
import { SurveyResponse, VoteSurveyCreateDto } from "./voteSurvey.types";
import { ModalMessage } from "../../components/ModalMessage/ModalMessage";

type FormData = {
  question: string;
  endDate: string;
  isMultipleChoice: boolean;
  answers: { value: string }[];
  clientKey: string;
  active: boolean;
};

interface LocationState {
  survey: SurveyResponse;
  clientKey: string;
}

function NewVoteSurvey() {

  const { postData } = usePost<VoteSurveyCreateDto, any>();
  const { putData } = usePut<VoteSurveyCreateDto, any>();

  const location = useLocation();
  const { survey, clientKey } = location.state as LocationState;

  let navigate = useNavigate();

  const [clientsKeys, setClientsKeys] = useState<any[]>([]);
  const [formDisabled, setFormDisabled] = useState<boolean>(false);
  const [showModal, setShowModal] = useState(false);


  const { handleSubmit, control, watch, reset, setValue, register, getValues } =
    useForm<FormData>({
      defaultValues: {
        question: "",
        endDate: "",
        isMultipleChoice: false,
        clientKey: "",
        active: true,
        answers: [{}],
      },
    });
  const { fields, append } = useFieldArray({
    control,
    name: "answers",
  });

  useEffect(() => {
    const clientsKeys = localStorage.getItem("clientsKeys") || "";

    if (clientsKeys) {
      setClientsKeys(JSON.parse(clientsKeys));
      setValue("clientKey", clientKey || JSON.parse(clientsKeys)[0].key);
    }
  }, []);

  useEffect(() => {
    if (!survey) return;
    setFormDisabled(true);

    reset({
      question: survey.question,
      endDate: formatDateStringYMD(survey.endAt),
      isMultipleChoice: survey.isMultipleChoice,
      clientKey: clientKey,
      active: survey.active,
      answers: survey.answers.map((answer) => {
        return { value: answer.description };
      }),
    });

  }, [survey]);

  const handleChange = (checked: boolean) => {
    setValue("isMultipleChoice", checked);
  };

  const handleChangeActive = (checked: boolean) => {
    if (survey) setValue("active", checked);
  };

  const onSubmit: SubmitHandler<FormData> = (data: FormData, e: any) => {
    e.preventDefault();
    setShowModal(true);
  };

  const handleFormSubmit = async () => {
    const data = getValues();
    const clientKey = data.clientKey;
    if (survey) {
      await putData(`clients/${clientKey}/surveys/${survey.uuid}`, {
        endDate: formatDateStringYMD(data.endDate),
        active: data.active,
      });
    } else {
      await postData(`clients/${clientKey}/surveys`, {
        question: data.question,
        endDate: formatDateStringYMD(data.endDate),
        isMultipleChoice: data.isMultipleChoice,
        active: data.active,
        answers: data.answers.map((answer) => answer.value),
      });
    }
    navigate("/vote")
  }

  return (
    <>
      <ModalMessage
        show={showModal}
        title={"Revise los datos"}
        message={"Verifique los datos cargados, luego no podrá modificarlos. La votación se activará automáticamente de manera inmediata"}
        type={"confirm"}
        onAccept={handleFormSubmit}
        onCancel={() => setShowModal(false)}
      />
      <TitleContainer title={"Votación"}>
        <fieldset className={styles.fieldset}>
          <legend>Crear Votación</legend>
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className={styles.container}>
              <TextCombo
                label="Cliente"
                props={{
                  control,
                  name: "clientKey",
                  rules: {
                    required: true,
                  },
                }}
                disabled
              >
                {clientsKeys.map((client, index) => (
                  <option key={index} value={client.key}>
                    {client.description}
                  </option>
                ))}
              </TextCombo>
              <div />
              <TextField
                label="Pregunta"
                props={{
                  name: "question",
                  control,
                  rules: {
                    required: true,
                    pattern: nonSpecialCharactersValidation,
                  },
                }}
                readOnly={formDisabled}
              />
              <div className={styles.flex}>
                <label
                  style={{ display: "flex", alignItems: "center", gap: "10px" }}
                >
                  <span className={styles.label}>Activa</span>
                  <Switch
                    onChange={handleChangeActive}
                    checked={watch("active")}
                    className={styles.reactSwitch}
                  />
                </label>
                <TextField
                  label="Fecha de finalización"
                  props={{
                    name: "endDate",
                    control,
                    rules: {
                      validate: { isFutureDate: isFutureDateValidation },
                      required: true,
                    },
                  }}
                  type="date"
                />
              </div>

              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  gap: "5px",
                }}
              >
                <span className={styles.label}>Opciones de respuesta</span>
                <Button
                  text={"+"}
                  styleOverride={{
                    width: "30px",
                    height: "30px",
                    borderRadius: "50%",
                  }}
                  disabled={formDisabled}
                  onClick={() => append({ value: "" })}
                />
              </div>
              {fields.length < 2 && (
                <>
                  {[...Array(1)].map((_, index) => (
                    <div
                      key={index}
                      style={{ display: "flex", gap: "5px", alignItems: "center" }}
                    >
                      <span className={styles.label}>{index + 1}</span>
                      <input
                        {...register(`answers.${index}.value`, { required: true, pattern: nonSpecialCharactersValidation })}
                        disabled={formDisabled}
                      />
                    </div>
                  ))}
                </>
              )}
              {fields.map((field, index) => (
                <div
                  key={field.id}
                  style={{ display: "flex", gap: "5px", alignItems: "center" }}
                >
                  <span className={styles.label}>{fields.length < 2 ? index + 2 : index + 1}</span>
                  <input
                    {...register(`answers.${fields.length < 2 ? index + 1 : index}.value`, { required: true, pattern: nonSpecialCharactersValidation })}
                    disabled={formDisabled}
                  />
                </div>
              ))}
              <label
                style={{ display: "flex", alignItems: "center", gap: "10px" }}
              >
                <span className={styles.label}>
                  ¿El usuario puede seleccionar más de una?
                </span>
                <Switch
                  onChange={handleChange}
                  checked={watch("isMultipleChoice")}
                  className={styles.reactSwitch}
                  disabled={formDisabled}
                />
              </label>

              <Button
                text={survey ? "Actualizar votación" : "Crear votación"}
                type="submit"
                styleOverride={{ width: "300px", margin: "0 auto" }}
              />
            </div>
          </form>
        </fieldset>
      </TitleContainer>
    </>
  );
}

export default NewVoteSurvey;
