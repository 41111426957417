
export function formatCurrency(value: number, language: string) {
  return new Intl.NumberFormat(language === "en" ? "en-US" : "es-AR", {
    style: "currency",
    currency: language === "en" ? "USD" : "ARS",
    minimumFractionDigits: 0,
  }).format(value);
}

export function formatDateStringDMY(date: string, separator = "-") {
  const dateString = date.split("T")[0].split("-");

  return [dateString[2], dateString[1], dateString[0]].join(separator);
}

export function formatDateStringYMD(date: string) {
  const dateString = date.split("T")[0].split("-");

  return [dateString[0], dateString[1], dateString[2]].join("-");
}

