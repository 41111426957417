import { useContext } from "react";
import { useNavigate } from "react-router-dom";
import { Context } from "../../App";
import stylesTable from "../../components/Table/Table.module.css";
import { ReactComponent as Delete } from "../../images/delete.svg";
import { ReactComponent as Edit } from "../../images/edit.svg";
import OpenInBrowser from "../../images/OpenInBrowser";
import {
  CampaignDetailedResponse,
  Product,
} from "../../pages/Campaign/campaign.types";
import { deleteInactiveCampaignProduct } from "../../services/CampaignServices";
import Button from "../Button/Button";
import styles from "./ProductsContainer.module.css";

interface Props {
  campaign: CampaignDetailedResponse;
}

export default function ProductsContainer({ campaign }: Props) {
  let navigate = useNavigate();

  const context = useContext(Context);

  const navigateToProduct = (uuid: string) => {
    navigate(`/campaigns/${campaign.key}/product/${uuid}`);
  };

  const handleDeleteProduct = (uuid: string) => {
    deleteInactiveCampaignProduct(campaign.key, uuid)
      .then(() =>
        context?.showAcceptModal("Éxito", "Producto eliminado", () => {
          window.location.reload();
        })
      )
      .catch(() =>
        context?.showErrorModal(
          "Error",
          "Ha ocurrido un error al intentar eliminar el producto"
        )
      );
  };

  const openProductInNewTab = (product: Product) => {
    window.open(
      `${process.env.REACT_APP_FRONT_CLIENT_URL}/collaborate/${campaign?.key}/checkout/${product.uuid}`,
      "_blank",
      "noreferrer"
    );
  };

  return (
    <>
      <div className={styles.addProductButton}>
        <Button
          text="Agregar producto"
          onClick={() => navigateToProduct("new")}
        />
      </div>
      {campaign?.products.length > 0 ? (
        <div className={stylesTable.overflowContainer}>
          <table className={stylesTable.container}>
            <thead>
              <tr>
                <th
                  colSpan={9}
                  style={{
                    textAlign: "center",
                    borderBottom: "1px solid white",
                  }}
                >
                  Productos de Campaña
                </th>
              </tr>
              <tr>
                <th>Imagen</th>
                <th>Nombre</th>
                <th>Precio</th>
                {campaign.client.isClub && <th>Stock</th>}
                {campaign.client.isClub && <th>Chances</th>}
                <th>Descripción</th>
                {campaign.client.isClub && <th>Tipo</th>}
                <th style={{ textAlign: "center" }}>Editar</th>
                {!campaign.active && (
                  <th style={{ textAlign: "center" }}>Eliminar</th>
                )}
                {!campaign.client.isClub && <th>Checkout</th>}
              </tr>
            </thead>
            <tbody>
              {campaign.products.map((product: any, i: number) => (
                <tr key={i}>
                  <td>
                    {product?.imageSrc && (
                      <img
                        src={product.imageSrc}
                        width="40px"
                        alt={`Imagen de ${product.name}`}
                      />
                    )}
                  </td>
                  <td>{product?.name}</td>
                  <td>
                    ${new Intl.NumberFormat("es-AR").format(product?.price)}
                  </td>
                  {campaign.client.isClub && <td>{product?.stock}</td>}
                  {campaign.client.isClub && <td>{product?.chances}</td>}
                  <td>{product?.description}</td>
                  {campaign.client.isClub && (
                    <td>
                      {product?.type === "product"
                        ? "Producto"
                        : "Contribución"}
                    </td>
                  )}
                  <td
                    onClick={() => navigateToProduct(product.uuid)}
                    className={styles.tableBtn}
                  >
                    <Edit onClick={() => navigateToProduct(product.uuid)} />
                  </td>
                  {!campaign.active && (
                    <td
                      onClick={() => handleDeleteProduct(product.uuid)}
                      className={styles.tableBtn}
                    >
                      <Delete />
                    </td>
                  )}
                  {!campaign.client.isClub && (
                    <td
                      onClick={() => {
                        openProductInNewTab(product);
                      }}
                      style={{
                        cursor: `${
                          campaign?.active === true ? "pointer" : "not-allowed"
                        }`,
                        textAlign: "center",
                      }}
                    >
                      <OpenInBrowser
                        currentColor={
                          campaign?.active === true ? "#000000" : "#C8C8C8"
                        }
                      />
                    </td>
                  )}
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      ) : (
        <h2 className={styles.noProducts}>Aún no se han agregado productos</h2>
      )}
    </>
  );
}
