import { useEffect, useState } from 'react';
import * as XLSX from 'xlsx';
import styles from './ExcelViewer.module.css';

type ExcelViewerProps = {
    file: File | undefined;
}

export default function ExcelViewer({ file }: ExcelViewerProps) {
    const [data, setData] = useState<any[]>([]);
    const [headers, setHeaders] = useState<string[]>([]);

    useEffect(() => {
        const loadWorkbook = async () => {
            if (!file) {
                console.error("Ningún archivo seleccionado");
                return;
            }

            const reader = new FileReader();
            reader.readAsArrayBuffer(file);
            reader.onload = async (e: any) => {
                const buffer = e.target.result;
                const workbook = XLSX.read(buffer, { type: 'array' });
                const worksheet = workbook.Sheets[workbook.SheetNames[0]];

                if (!worksheet['!ref']) {
                    console.error("El archivo Excel no tiene referencia definida");
                    return;
                }

                const range = XLSX.utils.decode_range(worksheet['!ref']);

                const newData: any[] = [];
                const newHeaders: string[] = [];

                const maxColumns = Math.min(range.e.c - range.s.c + 1, 4);

                for (let colIndex = range.s.c; colIndex <= range.s.c + maxColumns - 1; colIndex++) {
                    const cell = worksheet[XLSX.utils.encode_cell({ r: 0, c: colIndex })];
                    if (cell) {
                        newHeaders.push(cell.v);
                    }
                }

                for (let rowIndex = range.s.r + 1; rowIndex <= range.e.r; rowIndex++) {
                    const row: any = {};
                    for (let colIndex = range.s.c; colIndex <= range.s.c + maxColumns - 1; colIndex++) {
                        const cellRef = XLSX.utils.encode_cell({ r: rowIndex, c: colIndex });
                        const cell = worksheet[cellRef];
                        if (cell) {
                            const columnName = `column${colIndex + 1}`;
                            row[columnName] = cell.v;
                        }
                    }
                    newData.push(row);
                }

                setHeaders(newHeaders);
                setData(newData);
            };
        };

        loadWorkbook();
    }, [file]);

    return (
        <>
            <table className={styles.table}>
                <thead>
                    <tr className={styles.thead_tr}>
                        {headers.map((header, index) => (
                            <th key={index}>{header}</th>
                        ))}
                    </tr>
                </thead>
                <tbody>
                    {data.map((row, rowIndex) => (
                        <tr key={rowIndex} className={styles.tbody_tr}>
                            {Object.values(row).map((cell, cellIndex) => (
                                <td key={cellIndex}>{cell !== null && cell !== undefined ? String(cell) : ""}</td>
                            ))}
                        </tr>
                    ))}
                </tbody>
            </table>
        </>
    );
};
