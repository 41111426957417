import React from "react";
import styles from "./WarningText.module.css";

export default function WarningText({ text, show = true, center = false }: any) {
  return (
    show &&
    <div className={styles.container} style={{textAlign: center? "center": "left"}}>
      <span>{text}</span>
    </div>
  );
}