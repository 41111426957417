import React from 'react'
import { Winner } from '../../Interfaces/campaign'
import styles from "./Table.module.css"


export default function LotteryTable({ rows, winners }: { rows: number, winners?: Winner[] }) {
    return (
        <div className={styles.container}>
            <table>
                <thead>
                    <tr>
                        <th>Nombre</th>
                        <th>Email</th>
                        <th>Móvil</th>
                    </tr>
                </thead>
                {winners ?
                    <tbody>
                        <>
                            {winners.map((winner: Winner, i) => (
                                <tr key={i}>
                                    <td>{winner.fullname}</td>
                                    <td>{winner.email}</td>
                                    <td>{winner.phone}</td>
                                </tr>
                            ))}
                        </>
                    </tbody>
                    :
                    Array(rows).fill(undefined).map((e, i) => (
                        <tr>
                            <td></td>
                            <td></td>
                            <td></td>
                        </tr>
                    ))
                }
            </table>
        </div>
    )
}
