import { useState } from "react";
import { axiosAuth } from "../services/HttpService";
import axios from "axios";

export function useGet<T>() {
  const [error, setError] = useState<string | undefined>(undefined);
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState<T | undefined>(undefined);
  const core_url = process.env.REACT_APP_CORE_URL;

  const getData = async (path: string, auth: boolean = true) => {
    setIsLoading(true);

    const service = auth ? axiosAuth : axios;

    return service
      .get(`${core_url}/${path}`)
      .then((response) => {
        if (response.status !== 200) {
          setError(response.data.error);
        }
        if (response.status === 200) {
          setData(response.data);
        }
      })
      .catch((error) => setError(error.message))
      .finally(() => setIsLoading(false));
  };

  return { getData, data, isLoading, error };
}
