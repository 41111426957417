import styles from "./FieldsetLayout.module.css";
import { ReactComponent as Verified } from "../../images/verified.svg";
import { ReactComponent as NotVerified } from "../../images/verifiedOutline.svg";

type Props = {
  label: string;
  children: JSX.Element[] | JSX.Element;
  showCheck?: boolean;
  checked?: boolean;
};
export default function FieldsetLayout({
  label,
  children,
  showCheck,
  checked,
}: Props) {
  return (
    <fieldset className={styles.fieldset}>
      <legend>
        {label}
        {showCheck && (checked ? <Verified /> : <NotVerified />)}
      </legend>
      {children}
    </fieldset>
  );
}
