import { axiosAuth } from "./HttpService";

interface createCampaignResponse {
  key: string;
  client: any;
  title: string;
  coin: string;
  endDate: Date;
  awards: number;
  objective: number;
  paymentGateway: string;
  products: any;
  commission: string;
  active: boolean;
}

interface emailStyleResponse {
  headerFile: any;
  footerFile: any;
  backgroundColor: string;
  fontColor: string;
}


export const createCampaign = async (
  title: string,
  coin: string,
  awards: number,
  objective: number,
  clientKey: string,
  commission: number,
  active: boolean,
  endDate: Date,
  paymentGateway: string,
  landingUrl: string,
  facebook?: string,
  instagram?: string,
  tiktok?: string,
): Promise<createCampaignResponse> => {
  const resp = await axiosAuth
    .post(`/campaigns`, {
      title,
      coin,
      awards,
      objective,
      clientKey,
      commission,
      active,
      endDate,
      paymentGateway,
      landingUrl,
      facebook,
      instagram,
      tiktok
    })
    .then((response: any) => response.data)

  return resp;
};

export const createDefaultCampaign = async (
  clientKey: string,
): Promise<createCampaignResponse> => {
  const resp = await axiosAuth
    .post(`/campaigns/default`, {
      clientKey,
    })
    .then((response: any) => response.data)

  return resp;
};

export const updateCampaign = async (
  title: string,
  key: string,
  awards: number,
  objective: number,
  active: boolean,
  endDate: Date,
  paymentGateway: string,
  landingUrl: string,
  commission?: number,
  facebook?: string,
  instagram?: string,
  tiktok?: string,
): Promise<createCampaignResponse> => {
  const resp = await axiosAuth
    .put(`/campaigns/${key}`, {
      title,
      awards,
      objective,
      status: active,
      commission,
      paymentGateway,
      endDate,
      landingUrl,
      facebook,
      instagram,
      tiktok
    })
    .then((response: any) => response.data)

  return resp;
};


export const createProduct = async (
  key: string,
  formData: FormData,
): Promise<any> => {

  const resp = await axiosAuth
    .post(`/campaigns/${key}/product`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    })
    .then((response: any) => response.data)

  return resp;
};

export const updateProduct = async (
  key: string,
  productUuid: string,
  formData: FormData,
): Promise<any> => {

  const resp = await axiosAuth
    .put(`/campaigns/${key}/product/${productUuid}`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    })
    .then((response: any) => response.data)

  return resp;
};


export const updateTermsAndConditions = async (
  key: string,
  formData: FormData,
): Promise<any> => {

  const resp = await axiosAuth
    .post(`/campaigns/${key}/terms-and-conditions`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    })
    .then((response: any) => response.data)

  return resp;
};


export const updateEmailStyle = async (
  key: string,
  headerFile?: any,
  footerFile?: any,
  backgroundColor?: string,
  fontColor?: string,
): Promise<emailStyleResponse> => {

  const formData = new FormData();
  if (headerFile) formData.append("headerFile", headerFile);
  if (footerFile) formData.append("footerFile", footerFile);
  if (backgroundColor) formData.append("backgroundColor", backgroundColor);
  if (fontColor) formData.append("fontColor", fontColor);

  const resp = await axiosAuth
    .put(`/campaigns/${key}/email-style`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    })
    .then((response: any) => response.data)

  return resp;
};


export const addRewardTemplate = async (
  campaignKey: string,
  formData: FormData
): Promise<any> => {

  const resp = await axiosAuth
    .post(`/campaigns/${campaignKey}/reward-template`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    })
    .then((response: any) => response.data)

  return resp;
};


export const updateRewardTemplate = async (
  campaignKey: string,
  rewardUuid: string,
  formData: FormData
): Promise<any> => {

  const resp = await axiosAuth
    .put(`/campaigns/${campaignKey}/reward-template/${rewardUuid}`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    })
    .then((response: any) => response.data)

  return resp;
};

export const getTemplateDataBase = async (): Promise<any> => {
  return axiosAuth.get('users/import/excel-template', { responseType: 'arraybuffer' })
    .then((response) => {
      const blob = new Blob([response.data], { type: 'application/vnd.ms-excel' });
      const link = document.createElement('a');
      link.href = window.URL.createObjectURL(blob);
      link.setAttribute('download', 'users-database.xlsx');
      document.body.appendChild(link);
      link.click();
    })
}

export const deleteInactiveCampaignProduct = async (
  campaignKey: string,
  productUuid: string,
): Promise<any> => {

  const resp = await axiosAuth
    .delete(`/campaigns/${campaignKey}/product/${productUuid}`)
    .then((response: any) => response.data)

  return resp;
};

export const getCampaignDetail = async (campaignKey: string): Promise<any> => {
  const resp = await axiosAuth
    .get(`/campaigns/${campaignKey}/detail`)
    .then((response: any) => response.data)

  return resp;
};