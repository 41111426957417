import { ChangeEvent, useContext, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import Button from "../../components/Button/Button";
import { ModalMessage } from "../../components/ModalMessage/ModalMessage";
import Spacer from "../../components/Spacer/Spacer";
import styles from "../../components/Table/Table.module.css";
import TextCombo from "../../components/TextCombo/TextCombo";
import TitleContainer from "../../components/TitleContainer/TitleContainer";
import { useGet } from "../../hooks/useGet";
import { ReactComponent as Edit } from "../../images/edit.svg";
import { formatDateStringDMY } from "../../utils/format.utils";
import { Context } from "../../App";

type FormData = {
  clientKey: string;
};

function VoteSurveys() {
  const navigate = useNavigate();

  const { getData, data } = useGet<any[]>();

  const [showModal, setShowModal] = useState(false);
  const [clientsKeys, setClientsKeys] = useState<any[]>([]);
  const [isAnySurveyActive, setIsAnySurveyActive] = useState(false);
  const [clientSelected, setClientSelected] = useState<any>("");
  const context = useContext(Context);
  const { setShowLoading } = context!;

  const { control, getValues, setValue } = useForm<FormData>();

  useEffect(() => {
    const clientsKeys = localStorage.getItem("clientsKeys") || "";

    if (clientsKeys) {
      setClientsKeys(JSON.parse(clientsKeys));
      setValue("clientKey", JSON.parse(clientsKeys)[0].key);
      setClientSelected(JSON.parse(clientsKeys)[0].key);
    }
    async function fetch() {
      await getData(`clients/${JSON.parse(clientsKeys)[0].key}/surveys`);
    }
    fetch();
  }, []);

  useEffect(() => {
    if (clientSelected === "") return;
    setShowLoading(true);
    async function fetch() {

      await getData(`clients/${clientSelected}/surveys`)
        .then(() => setShowLoading(false))
    }
    fetch();
  }, [clientSelected]);

  useEffect(() => {
    setIsAnySurveyActive(data?.some((survey) => survey.active) || false);
  }, [data]);

  const handleEditSurvey = (survey: any) => {
    if (isAnySurveyActive && !survey?.active) {
      setShowModal(true);
      return;
    }
    navigate("/vote/edit", {
      state: { survey, clientKey: getValues("clientKey") },
    });
  };

  const handleClientChange = (e: ChangeEvent<HTMLSelectElement>) => {
    setValue("clientKey", e.target.value);
    setClientSelected(e.target.value);
  };

  return (
    <>
      <ModalMessage
        show={showModal}
        title={"Votación Activa"}
        message={"Para editar o crear una nueva votación debe desactivar la votación activa actual"}
        type={"error"}
        onAccept={() => setShowModal(false)}
      />
      <TitleContainer title="Votaciones">
        <form>
          <TextCombo
            label="Cliente"
            props={{
              control,
              name: "clientKey",
              onChange: handleClientChange,
            }}
          >
            {clientsKeys.map((client, index) => (
              <option key={index} value={client.key}>
                {client.description}
              </option>
            ))}
          </TextCombo>
        </form>
        <Spacer space="50px"></Spacer>
        <Button
          disabled={false}
          text={"Nuevo"}
          styleOverride={{ marginLeft: "auto" }}
          onClick={() => {
            handleEditSurvey(null)
          }}
        />
        <Spacer space="40px"></Spacer>
        <table className={styles.container}>
          <thead>
            <tr>
              <th>Estado</th>
              <th>Pregunta</th>
              <th>Fecha de fin</th>
              <th style={{ textAlign: "center" }}>Editar</th>
            </tr>
          </thead>
          <tbody>
            <>
              {data &&
                data!.map((survey, i) => (
                  <tr key={i}>
                    <td>{survey.active ? "Activa" : "Finalizada"}</td>
                    <td>{survey.question}</td>
                    <td>{formatDateStringDMY(survey.endAt)}</td>
                    <td
                      onClick={() => handleEditSurvey(survey)}
                      style={{ cursor: "pointer", textAlign: "center" }}
                    >
                      <Edit />
                    </td>
                  </tr>
                ))}
            </>
          </tbody>
        </table>
      </TitleContainer>
    </>
  );
}

export default VoteSurveys;
