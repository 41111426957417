import styles from './CampaignFilters.module.css';

interface Props {
    defaultOption?: boolean,
    setOption?: any,
    children?: JSX.Element[] | JSX.Element
}

export default function CampaignFilters({ defaultOption = false, setOption, children }: Props) {

    return (
        <div className="container" style={{ display: "flex", flexDirection: "row", justifyContent: "flex-start", alignItems: 'center', gap: '10px' }}>
                <span className={styles.text}>Campaña</span>
                <select
                    onChange={(e) => {
                        setOption(e.target.selectedOptions[0].value);
                    }}
                    className={styles.genericInput}
                >
                    {defaultOption && (
                        <option value="" disabled>Seleccionar campaña</option>
                    )}
                    {children}
                </select>
        </div>
    )
}
