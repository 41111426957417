import { SketchPicker } from "react-color";
import styles from "./ColorPicker.module.css";
import { useEffect, useState } from "react";

interface Props {
  setValue: any;
  label?: string;
  defaultColor?: string;
}

const parseColor = (colorString: string | undefined) => {
  if (!colorString) {
    return { r: 0, g: 0, b: 0, a: 1 };
  }

  // Si el color recibido es hexadecimal o rgba, se convierte a objeto de color
  if (/^#([A-Fa-f0-9]{3}){1,2}$/.test(colorString)) {
    // Si es hexadecimal
    return hexToRgb(colorString);
  } else if (colorString.startsWith("rgba")) {
    // Si es rgba
    const match = colorString.match(/(\d+(\.\d+)?)/g);
    if (match) {
      return { r: +match[0], g: +match[1], b: +match[2], a: +match[3] };
    }
  }

  return { r: 0, g: 0, b: 0, a: 1 };
};

// Función para convertir color hexadecimal a rgba
const hexToRgb = (hex: string) => {
  const shorthandRegex = /^#?([a-f\d])([a-f\d])([a-f\d])$/i;
  hex = hex.replace(shorthandRegex, (m, r, g, b) => {
    return r + r + g + g + b + b;
  });

  const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
  return result
    ? {
        r: parseInt(result[1], 16),
        g: parseInt(result[2], 16),
        b: parseInt(result[3], 16),
        a: 1,
      }
    : { r: 0, g: 0, b: 0, a: 0 };
};

export function ColorPicker({ setValue, label, defaultColor }: Props) {
  const [displayColorPicker, setDisplayColorPicker] = useState(false);
  const [color, setColor] = useState(parseColor(defaultColor));

  useEffect(() => {
    setColor(parseColor(defaultColor));
  }, [defaultColor]);

  const handleClick = () => {
    setDisplayColorPicker(!displayColorPicker);
  };

  const handleClose = () => {
    setDisplayColorPicker(false);
  };

  const handleChange = (newColor: any) => {
    setColor(newColor.rgb);
    setValue(`rgba(${newColor.rgb.r}, ${newColor.rgb.g}, ${newColor.rgb.b}, ${newColor.rgb.a})`);
  };

  return (
    <div>
      <div className={styles.container}>
        <span>{label}</span>
        <div className={styles.swatch} onClick={handleClick}>
          <div className={styles.color} style={{ backgroundColor: `rgba(${color.r}, ${color.g}, ${color.b}, ${color.a})` }} />
        </div>
      </div>
      {displayColorPicker && (
        <div className={styles.popover}>
          <div className={styles.cover} onClick={handleClose} />
          <SketchPicker color={color} onChange={handleChange} />
        </div>
      )}
    </div>
  );
}
