import { ReactComponent as Upload } from "../../images/upload.svg";
import styles from "./FileInput.module.css";

type Props = {
  handleFileChange: any;
  label: string;
  inputName: string;
  accept?: string
};

export default function FileInput({
  handleFileChange,
  label,
  inputName,
  accept
}: Props) {
  return (
    <div className={styles.fileInput}>
      <input
        type="file"
        name={inputName}
        id={inputName}
        onChange={handleFileChange}
        accept={accept || "image/*"}
      />
      <label htmlFor={inputName}>
        <Upload />
        <span>{label}</span>
      </label>
    </div>
  );
}
