import { UseControllerProps, useFormContext } from "react-hook-form";
import TextField from "../TextField/TextField";
import { HTMLAttributes, useEffect } from "react";
import { formatCurrency } from "../../utils/format.utils";

type Props = {
  label?: string;
  type?: string;
  color?: string;
  readOnly?: boolean;
  placeholder?: string;
  props: UseControllerProps<any>;
  children?: any;
  tooltipMessage?: string;
};

function CurrencyInput({
  label,
  tooltipMessage,
  color,
  readOnly = false,
  placeholder,
  props,
}: Props) {
  const { setValue, getValues, watch } = useFormContext();
  const { name } = props;

  useEffect(() => {
    const value = getValues(name);
    if (!value) return;
    const plainValue = value.replace(/[^0-9]/g, "");
    const formattedValue = formatCurrency(plainValue, "es");
    setValue(name, formattedValue);
  }, [watch(name)]);

  return (
    <TextField
      label={label}
      placeholder={placeholder}
      type="text"
      readOnly={readOnly}
      color={color}
      tooltipMessage={tooltipMessage}
      props={props}
    ></TextField>
  );
}

export default CurrencyInput;
