import stylesTable from "../../../components/Table/Table.module.css";
import stylesProductRewards from "./ProductRewards.module.css";
import { ReactComponent as Delete } from "../../../images/delete.svg";
import { ProductRewardsResponse } from "./product-reward.interface";

type Props = {
  productRewards: ProductRewardsResponse[];
  onDeleteReward: (id: string) => void;
};

export default function ProductsRewardsTable({
  productRewards,
  onDeleteReward,
}: Props) {
  return (
    <div className={stylesTable.overflowContainer}>
      {productRewards.length > 0 ? (
        <table className={stylesTable.container}>
          <thead>
          <tr>
            <th
              colSpan={9}
              style={{
                textAlign: "center",
                borderBottom: "1px solid white",
              }}
            >
              Recompensas agregadas
            </th>
          </tr>
          <tr>
            <th>Nombre</th>
            <th style={{ textAlign: "center" }}>Eliminar</th>
          </tr>
        </thead>
        <tbody>
          {productRewards.map((reward: any, i: number) => (
            <tr key={i}>
              <td>{reward?.title}</td>
              <td
                onClick={() => onDeleteReward(reward.id)}
                style={{ textAlign: "center" }}
              >
                <Delete />
              </td>
            </tr>
          ))}
          </tbody>
        </table>
      ) : (
        <p className={stylesProductRewards.noRewards}>Aún no hay recompensas agregadas</p>
      )}
    </div>
  );
}
