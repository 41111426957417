import Button from "../../components/Button/Button";
import LoginBox from "../../components/LoginBox/LoginBox";
import Spacer from "../../components/Spacer/Spacer";
import TextInput from "../../components/TextInput/TextInput";
import styles from "./Login.module.css";
import { useNavigate } from "react-router-dom";
import { useContext, useEffect, useState } from "react";
import { LoginService } from "../../services/LoginServices";
import LinkButton from "../../components/LinkButton/LinkButton";
import { Context, TRoleType } from "../../App";
import { useLocalStorage } from "../../hooks/useLocalStorage";

export default function Login() {
  const [fields, setFields] = useState({ email: "", password: "" });
  let context = useContext(Context);
  let navigate = useNavigate();
  const { setItem } = useLocalStorage();

  useEffect(() => {
    console.info("Environment:", process.env.REACT_APP_ENVIRONMENT);
    console.info("Version:", process.env.REACT_APP_VERSION);
    console.info("Api Url:", process.env.REACT_APP_CORE_URL);

    context?.clearUser();
  }, []);

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    handleLogin(fields.email, fields.password);
  };

  const handleLogin = async (email: string, password: string) => {
    context?.setShowLoading(true);
    LoginService(email, password)
      .then((loginResponse) => {
        if (loginResponse.response) {
          if (loginResponse.token && loginResponse.refreshToken && loginResponse.clientsKeys) {
            setItem("token", loginResponse.token);
            setItem("refreshToken", loginResponse.refreshToken);
            // localStorage.setItem("token", loginResponse.token);
            // localStorage.setItem("refreshToken", loginResponse.refreshToken);
            setItem("role", loginResponse.role as TRoleType);
            setItem("email", email);
            setItem("clientsKeys", loginResponse.clientsKeys);
            setItem("isClub", `${loginResponse.clientsKeys.some((client) => client.isClub)}`)
          }
          navigate("/dashboard");
        } else
          context?.showWarningModal(
            "CONTRASEÑA O EMAIL INCORRECTOS",
            "La contraseña o email es incorrecto, intente nuevamente.",
          );
      })
      .catch((exc: any) => {
        context?.showErrorModal("ERROR", exc);
      })
      .finally(() => {
        context?.setShowLoading(false);
      });
  };

  const onClickRecoverPassword = () => {
    navigate("/recover");
  };

  const handleSetState = (name: string, value: string) => {
    setFields({ ...fields, [name]: value });
  };

  const handleValidate = (name: string) => {
    switch (name) {
      case "email":
        if (fields[name] === "") return "Email has to be completed";
        break;
      case "password":
        if (fields[name] === "") return "Password has to be completed";
        break;
    }

    return "";
  };



  return (
    <div 
    className={styles.container}
    style={{height: 'calc(100vh - 115px)'}}
    >
      <LoginBox title="INICIAR SESIÓN">
        <form onSubmit={handleSubmit} className={styles.form}>
          <TextInput
            label="Email"
            initialValue={fields.email}
            name="email"
            placeholder=""
            setValue={handleSetState}
            type="text"
            validate={handleValidate}
          />
          <TextInput
            label="Contraseña"
            initialValue={fields.password}
            name="password"
            placeholder=""
            setValue={handleSetState}
            type="password"
            validate={handleValidate}
          />
          <Spacer space="20px" />
          <LinkButton onClick={onClickRecoverPassword}>
            Olvidé contraseña
          </LinkButton>
          <Spacer space="20px" />
          <Button
            text="LOGIN"
            type="submit"
            disabled={fields.email === "" || fields.password === ""}
          />
        </form>
      </LoginBox>
    </div>
  );
}