import { ProductRewardResponse } from "../pages/ProductReward/useGetProductRewards";
import { axiosAuth } from "./HttpService";

export const updateProductReward = async (
  title: string,
  key: string,
  awards: number,
  objective: number,
  active: boolean,
  endDate: Date,
  paymentGateway: string,
  commission?: number,
): Promise<void> => {
  const resp = await axiosAuth
    .put(`/campaigns/${key}`, {
      title,
      awards,
      objective,
      status: active,
      commission,
      paymentGateway,
      endDate,
    })
    .then((response: any) => response.data)

  return resp;
};

export const uploadPromotionalCodes = async (
  productReward: ProductRewardResponse,
  formData: FormData,
): Promise<void> => {
  const resp = await axiosAuth
    .post(`products/rewards/${productReward.id}/reward-items`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    })
    .then((response: any) => response.data)

  return resp;
}
