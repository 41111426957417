import { useEffect } from "react";
import { useGet } from "../../hooks/useGet";
import { ClientResponse } from "./clients.types";

export function useGetClients() {
  const { getData, data, isLoading, error } = useGet<ClientResponse[]>();

  useEffect(() => {
    async function fetch() {
      await getData("clients");
    }
    fetch();
  }, []);

  const clients = data;
  return { clients, isLoading, error };
}
