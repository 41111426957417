import { HtmlHTMLAttributes, useEffect, useState } from "react";
import styles from  "./TextInput.module.css";

interface Props {
  label: string;
  placeholder: string;
  setValue: any;
  name: string;
  validate: any;
  type: string;
  initialValue: string;
  props?: HtmlHTMLAttributes<HTMLInputElement>
}

function TextInput({
  label,
  placeholder,
  setValue,
  name,
  validate,
  type = "text",
  initialValue,
  props
}: Props) {
  const [showError, setShowError] = useState("");
  const [inputValue, setInputValue] = useState("");

  useEffect(() => {
    if (initialValue) {
      setValue(name, initialValue);
      setInputValue(initialValue);
    }
  }, [initialValue]);

  const handleOnChangeText = ({ target }: any) => {
    setValue(name, target.value);
    setInputValue(target.value);
  };

  const handleOnValidate = () => {
    const error = validate(name);
    if (error !== "" && error !== undefined) {
      setShowError(error);
    } else {
      setShowError("");
    }
  };

  return (
    <div className={styles.inputContainer}>
      <span className={styles.inputSpan}>{label}</span>
      <input
        type={type}
        onChange={handleOnChangeText}
        placeholder={placeholder}
        className={styles.input}
        onBlur={handleOnValidate}
        autoComplete="on"
        name={name}
        value={inputValue}
        {...props}
      />
      <div className={styles.inputErrorMessage}>{showError !== "" ? showError : ""}</div>
    </div>
  );
}

export default TextInput;
