import style  from "./LoginBox.module.css";

interface Props {
  title:string;
  children:any;
}

export default function LoginBox({ title, children }: Props) {
  return (
    <div className={style.loginBox}>
      <div className={style.loginBoxTitle}>{title}</div>
      {children}
    </div>
  );
}
