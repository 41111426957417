import styles from "./PdfViewer.module.css";

type Props = {
  fileUrl: string;
};

export default function PdfViewer({ fileUrl }: Props) {
  return (
    <div className={styles.container}>
      <iframe src={`${fileUrl}#toolbar=0`} height={300} title="pdf-preview"></iframe>
    </div>
  );
}
