import styles from "./NavbarUser.module.css";
import logo from "../../images/logo.png";
import { useContext, useEffect, useState } from "react";
import { Context } from "../../App";
import { useLocation, useNavigate } from "react-router-dom";
import { useLocalStorage } from "../../hooks/useLocalStorage";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars } from "@fortawesome/free-solid-svg-icons";

export default function NavbarUser() {
  let context = useContext(Context);
  const { getItem } = useLocalStorage<boolean>()
  const [pathname, setPathname] = useState("");
  const [menuActive, setMenuActive] = useState(false);
  let navigate = useNavigate();
  const isClub = getItem('isClub')

  const location = useLocation();

  useEffect(() => {
    setPathname(location.pathname);
  }, [location.pathname]);

  return (
    <div className={styles.container}>
      <img src={logo} alt="logo"></img>
      <button
        className={styles.hamburgerMenu}
        onClick={() => setMenuActive(!menuActive)}
      >
        <FontAwesomeIcon icon={faBars} size="xl" />
      </button>
      <div className={`${styles.menu} ${menuActive ? styles.menuActive : ""}`}>
        <div
          onClick={() => {
            navigate("/dashboard");
            setMenuActive(false);
          }}
          className={`${styles.item} ${pathname === "/dashboard" ? styles.active : ""
            }`}
        >
          DASHBOARD
        </div>
        {isClub && context?.getUserData()?.role !== "reader" && <div
          onClick={() => {
            navigate("/lottery");
            setMenuActive(false);
          }}
          className={`${styles.item} ${pathname === "/lottery" ? styles.active : ""
            }`}
        >
          SORTEO
        </div>
        }
        {
          context?.getUserData()?.role !== "reader" &&
          <div
            onClick={() => {
              navigate("/report");
              setMenuActive(false);
            }}
            className={`${styles.item} ${pathname === "/report" ? styles.active : ""
              }`}
          >
            REPORTES
          </div>
        }
        {
          context?.getUserData()?.role !== "reader" &&
          <div
            onClick={() => {
              navigate("/campaigns");
              setMenuActive(false);
            }}
            className={`${styles.item} ${pathname === "/campaigns" ? styles.active : ""
              }`}
          >
            CAMPAÑAS
          </div>
        }
        {
          context?.getUserData()?.role !== "reader" &&
          <div
            onClick={() => { navigate("/rewards") }}
            className={`${styles.item} ${pathname === "/rewards" ? styles.active : ""}`}
          >
            RECOMPENSAS
          </div>
        }
        {isClub && context?.getUserData()?.role !== "reader" && <div
          onClick={() => {
            navigate("/vote");
            setMenuActive(false);
          }}
          className={`${styles.item} ${pathname === "/vote" ? styles.active : ""
            }`}
        >
          VOTACIÓN
        </div>
        }
        {isClub && context?.getUserData()?.role !== "reader" && <div
          onClick={() => {
            navigate("/template-db");
            setMenuActive(false);
          }}
          className={`${styles.item} ${pathname === "/template-db" ? styles.active : ""
            }`}
        >
          BASE DE DATOS
        </div>
        }
        {context?.getUserData()?.role === "adminCKO" && (
          <>
            <div
              onClick={() => {
                navigate("/clients");
                setMenuActive(false);
              }}
              className={`${styles.item} ${pathname === "/clients" ? styles.active : ""}`}
            >
              CLIENTES
            </div>
            <div
              onClick={() => { navigate("/users"); }}
              className={`${styles.item} ${pathname === "/users" ? styles.active : ""}`}
            >
              USUARIOS
            </div>
            <div className={`${styles.logout} ${styles.item} ${menuActive ? styles.menuActive : ""}`}>
              <button
                onClick={() => {
                  navigate("/");
                }}
              >
                SALIR
              </button>
            </div>
          </>
        )}
      </div>
      <div className={`${styles.profile} ${menuActive ? styles.menuActive : ""}`}>
        <p>Bienvenido {context?.getUserData()?.email}</p>
        <button
          onClick={() => {
            navigate("/");
            setMenuActive(false);
          }}
        >
          Salir
        </button>
      </div>
    </div>
  );
}
